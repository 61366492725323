import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { langRefs } from 'i18n/langRefs';

const LangProvider = ({ children }) => {
  const { currentLang } = useSelector((state) => state.lang);
  return (
    <IntlProvider
      locale={currentLang.locale}
      messages={langRefs[currentLang.locale]}
    >
      {children}
    </IntlProvider>
  );
};
export default LangProvider;
