const instructorClassesPtMessages = {
  'instructor-classes.add-evaluation': 'Adicionar Avaliação',
  'instructor-classes.all-to-absence': 'Todos Ausentes',
  'instructor-classes.all-to-attendance': 'Todos Presentes',

  'instructor-classes.edit-evaluation': 'Editar Avaliação',

  'instructor-classes.frequency-form': 'Formulário de Presença',

  'instructor-classes.success-messag-frequency':
    'Frequência realizada com sucesso',

  'instructor-classes.view-lesson': 'Visualizar aula',
};

export default instructorClassesPtMessages;
