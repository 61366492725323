import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const studentHomeAdapter = createEntityAdapter({});

const studentHomeSlice = createSlice({
  name: 'studentHome',
  initialState: studentHomeAdapter.getInitialState({
    registerSuccessModal: {
      show: false,
    },
  }),
  reducers: {
    openRegisterSuccessModal: (state) => {
      state.registerSuccessModal.show = true;
    },

    closeRegisterSuccessModal: (state) => {
      state.registerSuccessModal.show = false;
    },
  },
  extraReducers: {},
});

export const { openRegisterSuccessModal, closeRegisterSuccessModal } =
  studentHomeSlice.actions;

export default studentHomeSlice.reducer;
