import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';
import removeDuplicateObjects from 'shared/utils/removeDuplicateObjects';

export const getLessonPlanChildren = createAsyncThunk(
  'lessonPlan/getLessonPlanChildren',
  async ({ parentLessonPlanId }) => {
    const response = await api.get('lesson-plan', {
      params: {
        parentLessonPlanId,
      },
    });

    return response.data;
  }
);

export const createLessonPlanChild = createAsyncThunk(
  'lessonPlan/createLessonPlanChild',
  async (lessonPlan) => {
    try {
      const response = await api.post('lesson-plan', {
        ...lessonPlan,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const getAllCurriculumComponents = createAsyncThunk(
  'lessonPlan/getAllCurriculumComponents',
  async (currentPage, { getState, dispatch }) => {
    const {
      lessons: { pagination },
    } = getState();

    try {
      const response = await api.get('curriculum-component', {
        params: {
          currentPage: currentPage ?? pagination.currentPage,
        },
      });

      if (response.data.currentPage < response.data.totalPages) {
        dispatch(getAllCurriculumComponents(response.data.currentPage + 1));
      }

      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

const lessonPlanAdapter = createEntityAdapter({});

const lessonPlanSlice = createSlice({
  name: 'lessonPlan',
  initialState: lessonPlanAdapter.getInitialState({
    lessonPlanChildren: [],
    curriculumComponents: [],
    reportModal: {
      show: false,
      data: null,
    },
  }),
  reducers: {
    addSubLessonPlanForm: (state) => {
      state.lessonPlanChildren = [...state.lessonPlanChildren, { id: 'new' }];
    },
  },
  extraReducers: {
    [getLessonPlanChildren.fulfilled]: (state, { payload }) => {
      state.lessonPlanChildren = payload.data;
      if (payload.data.length === 0) {
        state.lessonPlanChildren = [{ id: 'new' }];
      }
    },

    [createLessonPlanChild.fulfilled]: (state, { payload }) => {
      const result = state.lessonPlanChildren.filter(({ id }) => id !== 'new');

      state.lessonPlanChildren = [...result, payload.data];
    },

    [getAllCurriculumComponents.fulfilled]: (state, { payload }) => {
      state.curriculumComponents = removeDuplicateObjects([
        ...state.curriculumComponents,
        ...payload.data,
      ]);
    },
  },
});

export const { addSubLessonPlanForm } = lessonPlanSlice.actions;

export default lessonPlanSlice.reducer;
