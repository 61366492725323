import axios from 'axios';
import {
  getLocalKeyValue,
  removeLocalKeyValue,
  setLocalKeyValue,
} from 'services/localStorage';
import pedimosEduApi from 'services/api';

const PDM_AUTH_SERVICE = 'pdmAuthService';

const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

class AuthService {
  setSession = ({ tokenType, accessToken }) => {
    pedimosEduApi.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
    api.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
  };

  unsetSession = () => {
    pedimosEduApi.defaults.headers.common.Authorization = '';
    api.defaults.headers.common.Authorization = '';

    const storage = Object.keys({ ...localStorage });

    storage.forEach(
      (key) => key.includes(PDM_AUTH_SERVICE) && removeLocalKeyValue(key)
    );
  };

  setCredentialsInfo = () => {
    const accessToken = getLocalKeyValue('pdmAuthService:accessToken');
    const tokenType = getLocalKeyValue('pdmAuthService:tokenType');

    this.setSession({ accessToken, tokenType });
  };

  fetchUserInfo = async () => {
    try {
      const { data } = await pedimosEduApi.get('auth/me');
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  signIn = async (username, password) => {
    try {
      const { data } = await api.post('auth/token', {
        username,
        password,
        // TODO - Adicionar key para passar valor do FCM Token
        // fcmToken: userPushToken,
      });

      Object.entries(data).forEach(([key, value]) => {
        setLocalKeyValue(`${PDM_AUTH_SERVICE}:${key}`, value);
      });

      this.setSession(data);

      const userResponse = await this.fetchUserInfo();

      if (window.DidatiAppAuth) {
        window.DidatiAppAuth.postMessage(
          JSON.stringify({ user: userResponse, token: data })
        );
      }
      return userResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  signOut = async () => {
    this.unsetSession();
  };
}

export const UserAuthService = new AuthService();
