const basicEducationPtMessages = {
  'parameters.add-curriculum-component': 'Adicionar Componente Curricular',
  'parameters.add-education-level': 'Adicionar Nível de Ensino',
  'parameters.add-knowledge-area': 'Adicionar Área de Conhecimento',
  'parameters.add-training-type': 'Adicionar Tipo de Formação',
  'parameters.add-grade': 'Adicionar Turma',

  'parameters.curriculum-component': 'Componentes curriculares',
  'parameters.config-period': 'Configurar {period}',
  'parameters.config-academic-term': 'Configurar Período Letivo',

  'parameters.edit-curriculum-component': 'Editar Componente Curricular',
  'parameters.edit-education-level': 'Editar Nível de Ensino',

  'parameters.academic-term-config-required':
    'Configuração dos períodos no nível de ensino necessária',
  'parameters.academic-year': 'ano letivo',
  'parameters.add-academic-year': 'Adicionar Ano Letivo',

  'parameters.grades': 'Séries',

  'parameters.edit-academic-year': 'Editar Ano Letivo',
  'parameters.edit-grade': 'Editar Turma',
  'parameters.edit-knowledge-area': 'Editar Área de Conhecimento',
  'parameters.edit-training-type': 'Editar Tipo de Formação',

  'parameters.home': 'Parâmetros',

  'parameters.mark-config': 'Configurar Notas',

  'parameters.period-mark': 'Nota {index}° {type}',

  'parameters.block-delete-education-level':
    'Não é possível excluir um nível de ensino com períodos acadêmicos cadastrados',
};

export default basicEducationPtMessages;
