import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getAcademicYears = createAsyncThunk(
  'academicYears/getAcademicYears',
  async () => {
    try {
      const { data } = await api.get('academic-year');

      return data.data;
    } catch (error) {
      console.error('Academic Year API error: ', error.message);
      return [];
    }
  }
);

export const createAcademicYear = createAsyncThunk(
  'academicYears/createAcademicYear',
  async (academicYear) => {
    try {
      const response = await api.post('academic-year', {
        ...academicYear,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editAcademicYear = createAsyncThunk(
  'academicYears/editAcademicYear',
  async (academicYear) => {
    try {
      const response = await api.patch(`academic-year/${academicYear.id}`, {
        ...academicYear,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const academicYearsAdapter = createEntityAdapter({});

export const {
  selectAll: selectAcademicYears,
  selectEntities: selectAcademicYearsEntities,
  selectById: selectAcademicYearById,
} = academicYearsAdapter.getSelectors((state) => state.academicYear);

const academicYearsSlice = createSlice({
  name: 'academicYear',
  initialState: academicYearsAdapter.getInitialState({
    isLoading: false,
    academicYearModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    openAcademicYearModal: (state) => {
      state.academicYearModal = { show: true, type: 'new', data: null };
    },
    closeAcademicYearModal: (state) => {
      state.academicYearModal = { data: null, show: false, type: 'new' };
    },
    openEditAcademicYearModal: (state, { payload }) => {
      state.academicYearModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },
  },
  extraReducers: {
    [getAcademicYears.pending]: (state) => {
      state.isLoading = true;
    },
    [getAcademicYears.fulfilled]: (state, { payload }) => {
      academicYearsAdapter.setAll(state, payload);
      state.isLoading = false;
    },
    [getAcademicYears.rejected]: (state) => {
      state.isLoading = false;
    },

    [createAcademicYear.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        academicYearsAdapter.addOne(state, payload.data);
      }
    },

    [editAcademicYear.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        academicYearsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },
  },
});

export const {
  openAcademicYearModal,
  closeAcademicYearModal,
  openEditAcademicYearModal,
} = academicYearsSlice.actions;

export default academicYearsSlice.reducer;
