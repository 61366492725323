import generateID from 'shared/utils/generateID';
import api from '../../api';
import { educationLevels } from '../basicEducation/educationLevels-db';

let curriculumMatrixDb = [
  {
    id: 1,
    year: '2023',
    educationLevel: 'Ensino Médio',
    status: 'READY_TO_START',
  },
  {
    id: 2,
    year: '2022',
    educationLevel: 'Ensino Médio',
    status: 'IN_PROGRESS',
  },
  {
    id: 4,
    year: '2023',
    educationLevel: 'Ensino Médio',
    status: 'FINISHED',
  },
];

api.onGet('/api/curriculum-matrix').reply(() => {
  return [200, curriculumMatrixDb];
});

api.onPost('/api/curriculum-matrix').reply((request) => {
  const { year, educationLevelId } = JSON.parse(request.data);

  const newCurriculumMatrix = {
    id: generateID(),
    year,
    educationLevel: educationLevels.find(
      ({ id }) => id === Number(educationLevelId)
    ).name,
    status: 'READY_TO_START',
  };

  curriculumMatrixDb = [...curriculumMatrixDb, newCurriculumMatrix];

  return [201, newCurriculumMatrix];
});
