import generateID from 'shared/utils/generateID';
import api from '../../api';

let recurrencesDB = [
  {
    id: 1,
    weekday: 'MONDAY',
    start: '13:00:00',
    end: '14:00:00',
  },
];

const recurrencesByGrade = [
  {
    id: 1,
    weekday: 'MONDAY',
    start: '13:00:00',
    end: '14:00:00',
  },
  {
    id: 2,
    weekday: 'MONDAY',
    start: '14:00:00',
    end: '15:00:00',
  },
  {
    id: 3,
    weekday: 'MONDAY',
    start: '17:00:00',
    end: '18:00:00',
  },
  {
    id: 4,
    weekday: 'TUESDAY',
    start: '00:00:00',
    end: '02:00:00',
  },
];

api.onGet('lesson-plan/lesson-id/recurrences').reply(() => {
  return [200, { data: recurrencesDB }];
});

api.onGet('lesson-plan/grade-id/recurrences').reply(() => {
  return [200, { data: recurrencesByGrade }];
});

api.onPost('lesson-plan/lesson-id/recurrences').reply((request) => {
  const data = JSON.parse(request.data);

  const newRecurrence = {
    id: generateID(),
    weekday: data.weekday,
    start: data.start,
    end: data.end,
  };
  recurrencesDB = [...recurrencesDB, newRecurrence];

  return [201, newRecurrence];
});
