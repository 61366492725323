import api from '../../api';

const students1 = [
  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
];

const students2 = [
  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: null,
  },
];

const students3 = [
  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '001',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },

  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '002',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },

  {
    id: null,
    studentEnrollment: {
      id: '331198a9-94b9-43a8-ac7b-0e02605eba11',
      user: {
        id: 'fd4abfee-4413-4623-8c5f-de71819aa1b3',
        name: 'Sayro Damaceno',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'eb3871be-3aa2-45ab-a1a2-802e0216a7f2',
      user: {
        id: '46f7e375-24ae-47bf-93cd-24917499997a',
        name: 'Ash Williams',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'e1fb2d2c-3100-478c-a9ee-12600f619a8d',
      user: {
        id: '21eccc95-4b00-48af-ab34-bb506ad9bff5',
        name: 'Chris Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'b80f2322-dfa0-4947-853e-af8468b3dc45',
      user: {
        id: '1cc2663c-74d6-498a-978e-502a600d48cd',
        name: 'Leon S. Kennedy',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: '3fe4ea3d-faf0-434e-b5e2-205dfdb19605',
      user: {
        id: '7f4e8dc7-9bbf-447b-b4da-e1dd84eb6887',
        name: 'Claire Redfield',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
  {
    id: null,
    studentEnrollment: {
      id: 'd74b4a58-135e-44f4-8dfd-5ccf7d54bdb1',
      user: {
        id: 'f4642e2b-8de6-4bee-8a45-40b657068661',
        name: 'Jill Valentine',
      },
      grade: {
        id: 'fc7d0880-b9c2-11ed-bcdd-b77f3237581a',
        name: '1\u00b0 ano',
        isActive: true,
      },
      status: 'ACTIVE',
    },
    lesson: {
      id: '003',
      date: '2023-02-06',
      start: '08:40:00',
      end: '09:30:00',
      materialUsage: null,
      contentRegistration:
        'O impacto da globaliza\u00e7\u00e3o na cultura local.',
      note: null,
      lessonAccomplish: {
        id: '814675fd-dff2-4dfa-bc43-6b139485fffe',
        date: '2124-02-08',
        start: '08:25:00',
        end: '08:40:00',
        materialUsage: 'Um giz',
        contentRegistration:
          'O impacto da globaliza\u00e7\u00e3o na cultura local.',
        note: null,
      },
    },
    status: 'ATTENDANCE',
  },
];

const response = {
  list1: students1,
  list2: students2,
  list3: students3,
};

const frequencyDB = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  rowsPerPage: 20,
  totalPages: 1,
  totalRowCount: 6,
  data: response.list3,
};

api.onGet('frequencia').reply(() => {
  return [200, frequencyDB];
});
