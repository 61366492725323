import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getKnowledgeArea = createAsyncThunk(
  'knowledgeArea/getKnowledgeArea',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const { data } = await api.get('knowledge-area', {
        params: {
          academicYearId: selectedYear.id,
        },
      });
      return data.data;
    } catch (error) {
      console.error('Knowledge Area API error: ', error.message);
      return [];
    }
  }
);

export const createKnowledgeArea = createAsyncThunk(
  'knowledgeArea/createKnowledgeArea',
  async (knowledgeArea) => {
    try {
      const response = await api.post('knowledge-area', {
        ...knowledgeArea,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editKnowledgeArea = createAsyncThunk(
  'knowledgeArea/editKnowledgeArea',
  async (knowledgeArea) => {
    try {
      const response = await api.patch(`knowledge-area/${knowledgeArea.id}`, {
        ...knowledgeArea,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const knowledgeAreaAdapter = createEntityAdapter({});

export const {
  selectAll: selectKnowledgeArea,
  selectEntities: selectKnowledgeAreaEntities,
  selectById: selectKnowledgeAreaById,
} = knowledgeAreaAdapter.getSelectors((state) => state.knowledgeArea);

const knowledgeAreaSlice = createSlice({
  name: 'knowledgeArea',
  initialState: knowledgeAreaAdapter.getInitialState({
    isLoading: false,
    knowledgeAreaModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openKnowledgeAreaModal: (state) => {
      state.knowledgeAreaModal = { show: true, type: 'new', data: null };
    },
    closeKnowledgeAreaModal: (state) => {
      state.knowledgeAreaModal = { data: null, show: false, type: 'new' };
    },
    openEditKnowledgeAreaModal: (state, { payload }) => {
      state.knowledgeAreaModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },
  },
  extraReducers: {
    [getKnowledgeArea.fulfilled]: knowledgeAreaAdapter.setAll,

    [createKnowledgeArea.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        knowledgeAreaAdapter.addOne(state, payload.data);
      }
    },

    [editKnowledgeArea.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        knowledgeAreaAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },
  },
});

export const {
  setLoading,
  openKnowledgeAreaModal,
  closeKnowledgeAreaModal,
  openEditKnowledgeAreaModal,
} = knowledgeAreaSlice.actions;

export default knowledgeAreaSlice.reducer;
