import api from '../../api';

const registrationDB = [
  {
    id: '00001',
    grade: {
      id: '521340d3-1b5c-4168-9a94-27d1010f2163',
      name: '1ª série',
      academicYear: {
        id: '"9bf8541b-ccc3-4595-8aa1-bc2f95eb9bde"',
        year: '2022',
      },
      isActive: true,
    },
    user: {
      name: 'Zed Sigma',
      educationInstitutionEnrollment: '20221100001',
    },
    status: 'active',
  },
  {
    id: '00002',
    grade: {
      id: '521340d3-1b5c-4168-9a94-27d1010f2163',
      name: '1ª série',
      academicYear: {
        id: '"9bf8541b-ccc3-4595-8aa1-bc2f95eb9bde"',
        year: '2022',
      },
      isActive: true,
    },
    user: {
      name: 'Tristana Sigma',
      educationInstitutionEnrollment: '20221100002',
    },
    status: 'active',
  },
  {
    id: '00003',
    grade: {
      id: '521340d3-1b5c-4168-9a94-27d1010f2163',
      name: '1ª série',
      academicYear: {
        id: '"9bf8541b-ccc3-4595-8aa1-bc2f95eb9bde"',
        year: '2022',
      },
      isActive: true,
    },
    user: {
      name: 'Nami Sigma',
      educationInstitutionEnrollment: '20221100003',
    },
    status: 'active',
  },
];

api.onGet('/api/student-enrollment').reply(() => {
  return [200, registrationDB];
});

// api.onPost('/api/student-enrollment').reply((request) => {
//   const data = JSON.parse(request.data);

//   const newTrainingType = {
//     ...data,
//     id: generateID(),
//   };

//   registrationDB = [...registrationDB, newTrainingType];

//   return [201, newTrainingType];
// });

// export const registrations = registrationDB;
