const curriculumMatrixPtMessages = {
  'curriculum-matrix.add-curriculumMatrix': 'Adicionar Matriz Curricular',
  'curriculum-matrix.add-new-curriculumComponent':
    'Adicionar Componente Curricular',
  'curriculum-matrix.all-registered-curriculum-components':
    'Todos componentes curriculares foram cadastrados',

  'curriculum-matrix.empty-data-filter-selection':
    'Selecione o {year} e {educationLevel} para buscar as Séries',

  'curriculum-matrix.training-type-not-registered':
    'Tipos de formação não cadastrado',
};

export default curriculumMatrixPtMessages;
