import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import api from 'services/api';
import { datePatterns } from 'shared/constants/datePatterns';
import dateFormatter from 'shared/dateFormatters/dateFormatter';

export const getLessons = createAsyncThunk(
  'lessons/getLessons',
  async ({ currentPage, lessonPlanId, filter }, { getState }) => {
    const {
      lessons: { pagination, filters },
    } = getState();

    const currentFilter = { ...filters, ...filter };

    const response = await api.get(`lesson-plan/${lessonPlanId}/lesson`, {
      params: {
        currentPage: currentPage ?? pagination.currentPage,
        rowsPerPage: pagination.rowsPerPage,
        ...currentFilter,
      },
    });

    return { ...response.data, filters: currentFilter };
  }
);

export const createLesson = createAsyncThunk(
  'lessons/createLesson',
  async ({ lesson, lessonPlanId }) => {
    try {
      const response = await api.post(`/lesson-plan/${lessonPlanId}/lesson`, {
        ...lesson,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editLesson = createAsyncThunk(
  'lessons/editLesson',
  async ({ lesson, lessonId }, { getState }) => {
    const { lessonPlans } = getState();
    const lessonPlanId = lessonPlans.selectedLessonPlan.data.id;

    try {
      const response = await api.patch(
        `/lesson-plan/${lessonPlanId}/lesson/${lessonId}`,
        {
          ...lesson,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editReleasedLesson = createAsyncThunk(
  'lessons/editReleasedLesson',
  async ({ lessonReleased, lesson, lessonAccomplishId }, { getState }) => {
    const { lessonPlans } = getState();
    const lessonPlanId = lessonPlans.selectedLessonPlan.data.id;

    try {
      const response = await api.patch(
        `/lesson-plan/${lessonPlanId}/lesson/${lesson.id}/accomplish/${lessonAccomplishId}`,
        {
          ...lessonReleased,
        }
      );
      const data = await response.data;
      return {
        lesson,
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const removeLesson = createAsyncThunk(
  'lessons/removeLesson',
  async ({ lessonPlanId, lessonId }) => {
    try {
      const response = await api.delete(
        `lesson-plan/${lessonPlanId}/lesson/${lessonId}`
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const launchLesson = createAsyncThunk(
  'lessons/launchLesson',
  async (lesson, { getState }) => {
    const { lessonPlans } = getState();
    const lessonPlanId = lessonPlans.selectedLessonPlan.data.id;

    try {
      const response = await api.post(
        `lesson-plan/${lessonPlanId}/lesson/${lesson.id}/accomplish`,
        {
          ...lesson,
        }
      );
      const data = await response.data;
      return {
        lesson,
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const launchManyLessons = createAsyncThunk(
  'lessons/launchManyLessons',
  async ({ lessonToReleased, sameDayLessons }, { dispatch }) => {
    try {
      await Promise.all(
        sameDayLessons.map(async (lesson) => {
          await dispatch(
            launchLesson({
              ...lesson,
              note: lessonToReleased.note || '',
              contentRegistration: lessonToReleased.contentRegistration || '',
              materialUsage: lessonToReleased.materialUsage || '',
            })
          );
        })
      );

      return { success: true };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const getAllLessonsByDay = createAsyncThunk(
  'lessons/getAllLessonsByDay',
  async ({ date }, { getState }) => {
    const { lessonPlans } = getState();
    const lessonPlanId = lessonPlans.selectedLessonPlan.data.id;

    try {
      const { data } = await api.get(`lesson-plan/${lessonPlanId}/lesson`, {
        params: {
          start: dateFormatter({
            date,
            pattern: datePatterns.yearMonthDay,
          }),
          end: dateFormatter({
            date,
            pattern: datePatterns.yearMonthDay,
          }),
        },
      });

      return data.data.filter((lesson) => !lesson.accomplished);
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const lessonPlansToChangeData = createAsyncThunk(
  'lessons/getLessonPlans',
  async ({ gradeId, currentPage = 1 }, { dispatch }) => {
    const response = await api.get('lesson-plan', {
      params: {
        gradeId,
        currentPage,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(
        lessonPlansToChangeData({
          currentPage: data.currentPage + 1,
        })
      );
    }

    return data;
  }
);

export const getLessonsToChange = createAsyncThunk(
  'lessons/getLessonsToChange',
  async ({ start, end, lessonPlanId }) => {
    const response = await api.get(`lesson-plan/${lessonPlanId}/lesson`, {
      params: {
        start,
        end,
      },
    });

    return response.data;
  }
);

export const changeLesson = createAsyncThunk(
  'lessons/changeLesson',
  async (body) => {
    try {
      const response = await api.patch(`/lesson-plan/lesson/swap`, {
        ...body,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const lessonAdapter = createEntityAdapter({});

export const {
  selectAll: selectLessons,
  selectEntities: selectLessonsEntities,
  selectById: selectLessonById,
} = lessonAdapter.getSelectors((state) => state.lessons);

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState: lessonAdapter.getInitialState({
    isLoading: false,
    lessonModal: {
      show: false,
      data: '',
    },
    ChangeLessonModal: {
      show: false,
      data: '',
    },
    lessonsByDay: [],
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: 10,
      totalPages: 1,
      totalRowCount: 0,
    },
    filters: {},
    lessonPlansSelect: [],
    lessonsToChange: [],
  }),
  reducers: {
    openLessonModal: (state, { payload }) => {
      state.lessonModal = { data: payload, show: true };
    },
    closeLessonModal: (state) => {
      state.lessonModal = { data: '', show: false };
      state.lessonsByDay = [];
    },
    editReleased: (state) => {
      state.lessonModal.data.viewOnly = false;
    },

    openChangeLessonModal: (state, { payload }) => {
      state.ChangeLessonModal = { data: payload, show: true };
    },
    closeChangeLessonModal: (state) => {
      state.ChangeLessonModal = { data: '', show: false };
    },
  },
  extraReducers: {
    [getLessons.fulfilled]: (state, { payload }) => {
      lessonAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.filters = payload.filters;
    },

    [createLesson.fulfilled]: (state, action) => {
      const { payload } = action;
      if (payload.success) {
        lessonAdapter.addOne(state, {
          ...payload.data,
        });
      }
    },

    [editLesson.fulfilled]: (state, action) => {
      const { payload } = action;
      if (payload.success) {
        lessonAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [launchLesson.fulfilled]: (state, action) => {
      const { payload } = action;
      if (payload.success) {
        lessonAdapter.updateOne(state, {
          id: payload.lesson.id,
          changes: { ...payload.lesson, accomplished: payload.data },
        });
      }
    },

    [editReleasedLesson.fulfilled]: (state, action) => {
      const { payload } = action;
      if (payload.success) {
        lessonAdapter.updateOne(state, {
          id: payload.lesson.id,
          changes: { ...payload.lesson, accomplished: payload.data },
        });
      }
    },

    [getAllLessonsByDay.fulfilled]: (state, { payload }) => {
      state.lessonsByDay = payload;
    },

    [removeLesson.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        lessonAdapter.removeOne(state, payload.data.id);
      }
    },

    [lessonPlansToChangeData.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        state.lessonPlansSelect = payload.data;
      } else {
        state.lessonPlansSelect = [...state.lessonPlansSelect, ...payload.data];
      }
    },

    [getLessonsToChange.fulfilled]: (state, { payload }) => {
      state.lessonsToChange = payload.data;
    },
  },
});

export const {
  openLessonModal,
  closeLessonModal,
  editReleased,
  openChangeLessonModal,
  closeChangeLessonModal,
} = lessonsSlice.actions;

export default lessonsSlice.reducer;
