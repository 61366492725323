const apiPtMessages = {
  END_TIME_OVERLAP: 'Horário ínvalido',

  INTERVAL_TIME_OVERLAP: 'Intervalo de horas ínvalido',
  INVALID_DATE_INTERVAL_ERROR:
    'A data final deve ser maior que a data inicial.',

  LESSON_PLAN_ALREADY_RECORDED: 'Componente curricular já cadastrado!',

  START_TIME_OVERLAP: 'Horário ínvalido',

  YEARS_ARE_NOT_THE_SOME_ERROR: 'As datas devem corresponder ao mesmo ano.',

  MONDAY: 'Segunda-feira',
  TUESDAY: 'Terça-feira',
  WEDNESDAY: 'Quarta-feira',
  THURSDAY: 'Quinta-feira',
  FRIDAY: 'Sexta-feira',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
};

export default apiPtMessages;
