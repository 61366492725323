import api from '../../api';

const response = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  rowsPerPage: 20,
  totalPages: 1,
  totalRowCount: 3,
  data: [
    {
      id: 1,
      name: 'Prova 1',
      description: 'Prova referente ao cap 1 e 2',
      evaluationType: 'PROOF',
      highest: 10.0,
      date: '2023/03/03',
      academicTerm: {
        id: 'cf765e4b-a911-4d89-9156-e30421f3570a',
        start: '2023-07-01',
        end: '2023-12-01',
      },
      grade: {
        id: 'a1c46ff0-c750-11ed-92ae-d1cb28fb1215',
        name: '1\u00b0 ano',
        trainingTypes: [],
        educationLevel: {
          id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
          name: 'Ensino M\u00e9dio',
          isActive: true,
          academicTerms: [
            {
              id: 'cf765e4b-a911-4d89-9156-e30421f3570a',
              start: '2023-07-01',
              end: '2023-12-01',
            },
            {
              id: '2',
              start: '2023-07-01',
              end: '2023-12-01',
            },
            {
              id: '3',
              start: '2023-07-01',
              end: '2023-12-01',
            },
          ],
        },
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
      },
    },
    {
      id: 2,
      name: 'Prova 2',
      description: 'Prova referente ao cap 3 e 4',
      evaluationType: 'PROOF',
      highest: 10.0,
      date: '2023/04/04',
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino M\u00e9dio',
        type: 'TRIMESTER',
        isActive: true,
        academicTerms: [],
      },
      academicTerm: {
        id: 'cf765e4b-a911-4d89-9156-e30421f3570a',
        start: '2023-07-01',
        end: '2023-12-01',
      },
      grade: {
        id: 'a1c46ff0-c750-11ed-92ae-d1cb28fb1215',
        name: '1\u00b0 ano',
        trainingTypes: [],
        educationLevel: {
          id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
          name: 'Ensino M\u00e9dio',
          isActive: true,
        },
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
      },
    },
    {
      id: 3,
      name: 'Prova 3',
      description: 'Prova referente ao cap 5 e 6',
      evaluationType: 'PROOF',
      highest: 10.0,
      date: '2023/05/05',
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino M\u00e9dio',
        type: 'TRIMESTER',
        isActive: true,
        academicTerms: [],
      },
      academicTerm: {
        id: 'cf765e4b-a911-4d89-9156-e30421f3570a',
        start: '2023-07-01',
        end: '2023-12-01',
      },
      grade: {
        id: 'a1c46ff0-c750-11ed-92ae-d1cb28fb1215',
        name: '1\u00b0 ano',
        trainingTypes: [],
        educationLevel: {
          id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
          name: 'Ensino M\u00e9dio',
          isActive: true,
        },
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
      },
    },
  ],
};

api.onGet('evaluation').reply(() => {
  return [200, response];
});
