const validationPtMessages = {
  'validate.cpf': 'Informe um CPF válido',
  'validate.data': 'Dado inválido',
  'validate.date-overlap': 'A sobreposição de datas não é permitida',

  'commons.fill.all.fields': 'Preencha todos os campos para continuar',
  'validate.initials.character.types':
    'Este campo permite apenas números, letras e hífens',
  'validate.mail': 'Informe um e-mail válido',
  'validate.min.character': 'Mínimo {min} caracteres',
  'validate.number': 'Informe um número válido',

  'validate.required': 'Campo obrigatório',
  'validate.required-fields': '* Campos obrigatórios',
  'validate.required-info': 'Essa informação é obrigatória',

  'validate.end-greater-than-start': 'Data final deve ser maior que a inicial',

  'validate.highest-greater-than-average':
    'Média deve ser maior que a Nota Maxíma Anual',

  'validate.less-than-to': 'Este campo deve ser menor que {value}',
  'validate.less-than-or-equal-to':
    'Este campo deve ser igual ou menor que {value}',

  'validate.positive': 'Deve ser um número positivo',

  'validate.string.character.types':
    'Este campo permite apenas números, letras, hífens e espaços',
  'validate.start-greater-than-last-period':
    'Data inicial deve ser maior que a data do período anterior',
  'validate.sum-equal-or-less': 'A somatória deve ser igual ou menor a {value}',

  'validate.textField': 'Digite apenas letras',
  'value.string.max_length':
    'certifique-se de que este valor tenha no máximo {max} caracteres',
  'value.string.range_length':
    'certifique-se de que este valor tenha entre {min} e {max} caracteres',
};

export default validationPtMessages;
