import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import api from 'services/api';

export const getEvents = createAsyncThunk(
  'events/getEvents',
  async (currentPage, { dispatch }) => {
    const response = await api.get('academic-calendar', {
      params: {
        currentPage: currentPage ?? null,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(getEvents(data.currentPage + 1));
    }

    return data.data;
  }
);

export const getNationalHolidays = createAsyncThunk(
  'events/getNationalHolidays',
  async () => {
    const currentYear = new Date().getFullYear();

    const response = await axios.get(
      `https://brasilapi.com.br/api/feriados/v1/${currentYear}`
    );
    const data = await response.data;

    const parsedData = data.map((event, index) => {
      return {
        id: `${event.date}-${index}`,
        title: event.name,
        type: 'NATIONAL',
        startDate: event.date,
        endDate: event.date,
        startHour: '00:00:00',
        endHour: '23:59:59',
        editable: false,
      };
    });

    return parsedData;
  }
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (event) => {
    try {
      const response = await api.post('academic-calendar', {
        ...event,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editEvent = createAsyncThunk('events/editEvent', async (event) => {
  try {
    const response = await api.patch(`academic-calendar/${event.id}`, {
      ...event,
    });
    const data = await response.data;
    return {
      success: true,
      data,
    };
  } catch (err) {
    return {
      success: false,
      data: err.response.data,
    };
  }
});

export const removeEvent = createAsyncThunk(
  'events/removeEvent',
  async (id) => {
    try {
      const response = await api.delete(`academic-calendar/${id}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const eventsAdapter = createEntityAdapter({});

export const {
  selectAll: selectEvents,
  selectEntities: selectEventsEntities,
  selectById: selectEventsById,
} = eventsAdapter.getSelectors((state) => state.events);

const eventsSlice = createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState({
    showArchivingModal: false,
  }),
  reducers: {
    setSelectedEvent(state, action) {
      state.selectedEvent = action.payload;
    },
    updateEvent(state, action) {
      const filteredState = state.events.filter(
        ({ id }) => id !== action.payload.id
      );
      state.events = [...filteredState, action.payload];
    },
    deleteEvent(state, action) {
      const filteredState = state.events.filter(
        ({ id }) => id !== action.payload
      );
      state.events = filteredState;
    },
    clearEvents(state) {
      state.events = [];
    },
  },
  extraReducers: {
    [getEvents.fulfilled]: eventsAdapter.addMany,
    [getNationalHolidays.fulfilled]: eventsAdapter.addMany,

    [createEvent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        eventsAdapter.addOne(state, payload.data);
      }
    },

    [editEvent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        eventsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [removeEvent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        eventsAdapter.removeOne(state, payload.data.id);
      }
      return state;
    },
  },
});

export const { setSelectedEvent, updateEvent, deleteEvent, clearEvents } =
  eventsSlice.actions;

export default eventsSlice.reducer;
