import checkToken from 'auth/checkToken';
import axios from 'axios';
import { DEFAULT_PATHS } from 'config';
import { UserAuthService } from 'services/pdmAuth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (config) => config,

  (error) => {
    if (error.response.status === 401) {
      UserAuthService.signOut();
    }
    return Promise.reject(error);
  }
);

export default api;

api.interceptors.request.use(
  async (config) => {
    if (checkToken()) {
      window.location.href = DEFAULT_PATHS.LOGIN;
      return Promise.reject(new Error('Token expired'));
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
