import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getGrades = createAsyncThunk(
  'grades/getGrades',
  async ({ currentPage }, { dispatch, getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get('grade', {
      params: {
        currentPage: currentPage ?? null,
        academicYearId: selectedYear.id,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(
        getGrades({
          currentPage: data.currentPage + 1,
          academicYearId: selectedYear.id,
        })
      );
    }

    return data;
  }
);

export const createGrade = createAsyncThunk(
  'grades/createGrade',
  async (grade) => {
    try {
      const response = await api.post('grade', {
        ...grade,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editGrade = createAsyncThunk('grades/editGrade', async (grade) => {
  try {
    const response = await api.patch(`grade/${grade.id}`, {
      ...grade,
    });
    const data = await response.data;
    return {
      success: true,
      data,
    };
  } catch (err) {
    return {
      success: false,
      data: err.response.data,
    };
  }
});

export const getGradeById = createAsyncThunk(
  'grades/getGradeById',
  async (gradeId) => {
    try {
      const response = await api.get(`grade/${gradeId}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error('Grades API error: ', error.message);
      return [];
    }
  }
);

export const createAcademicTermMarks = createAsyncThunk(
  'grades/createAcademicTermMarks',
  async ({ academicTermMarks, gradeId }) => {
    try {
      const response = await api.post(`grade/${gradeId}/academic-term-mark`, {
        academicTermMarks,
      });
      const data = await response.data;
      return {
        success: true,
        data: { ...data, gradeId },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editAcademicTermMarks = createAsyncThunk(
  'grades/editAcademicTermMarks',
  async ({ academicTermMarks, gradeId }) => {
    try {
      const response = await api.patch(`grade/${gradeId}/academic-term-mark`, {
        academicTermMarks,
      });
      const data = await response.data;
      return {
        success: true,
        data: { ...data, gradeId },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const gradesAdapter = createEntityAdapter({});

export const {
  selectAll: selectGrades,
  selectEntities: selectGradesEntities,
  selectById: selectGradeById,
} = gradesAdapter.getSelectors((state) => state.grades);

const gradesSlice = createSlice({
  name: 'grades',
  initialState: gradesAdapter.getInitialState({
    isLoading: false,
    gradeModal: {
      show: false,
      type: 'new',
      data: null,
    },
    AcademicTermMarkModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openGradeModal: (state) => {
      state.gradeModal = { show: true, type: 'new', data: null };
    },
    closeGradeModal: (state) => {
      state.gradeModal = { data: null, show: false, type: 'new' };
    },
    openEditGradeModal: (state, { payload }) => {
      state.gradeModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },

    openAcademicTermMarkModal: (state, { payload }) => {
      state.AcademicTermMarkModal = { show: true, type: 'new', data: payload };
    },
    closeAcademicTermMarkModal: (state) => {
      state.AcademicTermMarkModal = { data: null, show: false, type: 'new' };
    },
    openEditAcademicTermMarkModal: (state, { payload }) => {
      state.AcademicTermMarkModal = { show: true, type: 'edit', data: payload };
    },
  },
  extraReducers: {
    [getGrades.pending]: (state) => {
      state.isLoading = true;
    },
    [getGrades.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        gradesAdapter.setAll(state, payload.data);
        state.isLoading = false;
      } else {
        gradesAdapter.addMany(state, payload.data);
        state.isLoading = false;
      }
    },
    [getGrades.rejected]: (state) => {
      state.isLoading = false;
    },

    [createGrade.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        gradesAdapter.addOne(state, payload.data);
      }
    },

    [editGrade.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        gradesAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [getGradeById.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedGrade = payload.data;
      }
    },

    [createAcademicTermMarks.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        gradesAdapter.updateOne(state, {
          id: payload.data.gradeId,
          changes: { academicTermMarks: payload.data.data },
        });
      }
    },

    [editAcademicTermMarks.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        gradesAdapter.updateOne(state, {
          id: payload.data.gradeId,
          changes: { academicTermMarks: payload.data.data },
        });
      }
    },
  },
});

export const {
  setLoading,
  openEditGradeModal,
  openGradeModal,
  closeGradeModal,
  openAcademicTermMarkModal,
  closeAcademicTermMarkModal,
  openEditAcademicTermMarkModal,
} = gradesSlice.actions;

export default gradesSlice.reducer;
