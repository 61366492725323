import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const lessonPlans = {
  plans: lazy(() => import('views/lessonPlans/LessonPlansPage')),
  lessonPlan: lazy(() => import('views/lessonPlan/LessonPlanPage')),
  lessons: lazy(() => import('views/lessons/LessonsPage')),
  lessonAttendance: lazy(() =>
    import('views/lessonPlans/lessonAttendance/LessonAttendancePage')
  ),
  evaluationsPage: lazy(() => import('views/evaluations/EvaluationsPage')),
  evaluationScorePage: lazy(() =>
    import('views/instructorView/evaluationScores/EvaluationScoresPage')
  ),
  allScorePage: lazy(() => import('views/scores/ScoresPage')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const lessonPlansRoute = {
  path: `${appRoot}/planos-de-aula`,
  exact: true,
  icon: 'notebook-1',
  label: 'menu.lesson-plans',
  component: lessonPlans.plans,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/planos-de-aula`,
  subs: [
    {
      path: '/gerenciar',
      label: 'lesson-plans.add-lesson-plan',
      component: lessonPlans.lessonPlan,
      exact: true,
      hideInMenu: true,
    },
    {
      path: '/gerenciar/aulas',
      label: 'lesson-plans.show-lesson-plan',
      component: lessonPlans.lessons,
      exact: true,
      hideInMenu: true,
    },
    {
      path: '/gerenciar/aulas/frequencia',
      label: 'lesson-plans.lessons',
      component: lessonPlans.lessonAttendance,
      exact: true,
      hideInMenu: true,
    },
    {
      path: '/gerenciar/avaliacoes',
      component: lessonPlans.evaluationsPage,
      exact: true,
      hideInMenu: true,
    },
    {
      path: '/gerenciar/avaliacoes/notas',
      component: lessonPlans.evaluationScorePage,
      exact: true,
      hideInMenu: true,
    },
    {
      path: '/gerenciar/notas',
      component: lessonPlans.allScorePage,
      exact: true,
      hideInMenu: true,
    },

    // {
    //   path: '/:lessonPlanId?/avaliacoes/:gradeId?/:curriculumComponentId?',
    //   component: lessonPlans.evaluationsPage,
    //   hideInMenu: true,
    //   exact: true,
    // },
    // {
    //   path: '/:lessonPlanId?/avaliacoes/:gradeId?/:curriculumComponentId?/avaliacao/:evaluationId?',
    //   component: lessonPlans.evaluationScorePage,
    //   hideInMenu: true,
    //   exact: true,
    // },
    // {
    //   path: '/:lessonPlanId?/notas/:gradeId?/:curriculumComponentId?',
    //   component: lessonPlans.allScorePage,
    //   hideInMenu: true,
    //   exact: true,
    // },
    // {
    //   path: '/aulas/:lessonPlanId?/:lessonId/:gradeId',
    //   component: lessonPlans.lessonAttendance,
    //   label: 'lesson-plans.lessons',
    //   exact: true,
    //   hideInMenu: true,
    // },
  ],
};

export default lessonPlansRoute;
