import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getTrainingTypes = createAsyncThunk(
  'trainingTypes/getTrainingTypes',
  async () => {
    try {
      const { data } = await api.get('training-type');
      return data.data;
    } catch (error) {
      console.error('Training Types API error: ', error.message);
      return [];
    }
  }
);

export const createTrainingType = createAsyncThunk(
  'trainingTypes/createTrainingType',
  async (trainingType) => {
    try {
      const response = await api.post('training-type', {
        ...trainingType,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editTrainingType = createAsyncThunk(
  'trainingTypes/editTrainingType',
  async (trainingType) => {
    try {
      const response = await api.patch(`training-type/${trainingType.id}`, {
        ...trainingType,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const trainingTypesAdapter = createEntityAdapter({});

export const {
  selectAll: selectTrainingTypes,
  selectEntities: selectTrainingTypesEntities,
  selectById: selectTrainingTypeById,
} = trainingTypesAdapter.getSelectors((state) => state.trainingTypes);

const trainingTypesSlice = createSlice({
  name: 'trainingTypes',
  initialState: trainingTypesAdapter.getInitialState({
    isLoading: false,
    trainingTypeModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    openTrainingTypeModal: (state) => {
      state.trainingTypeModal = { show: true, type: 'new', data: null };
    },
    closeTrainingTypeModal: (state) => {
      state.trainingTypeModal = { data: null, show: false, type: 'new' };
    },
    openEditTrainingTypeModal: (state, { payload }) => {
      state.trainingTypeModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },
  },
  extraReducers: {
    [getTrainingTypes.fulfilled]: trainingTypesAdapter.setAll,

    [createTrainingType.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        trainingTypesAdapter.addOne(state, payload.data);
      }
    },

    [editTrainingType.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        trainingTypesAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },
  },
});

export const {
  setLoading,
  openTrainingTypeModal,
  closeTrainingTypeModal,
  openEditTrainingTypeModal,
} = trainingTypesSlice.actions;

export default trainingTypesSlice.reducer;
