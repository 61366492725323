import generateID from 'shared/utils/generateID';
import api from '../../api';

let usersDB = [
  {
    id: '1',
    name: 'Nataniel de Mello Fogiatto',
    type: 'INSTRUCTOR',
    userName: 'michael@pedimos.com.br',
    avatar: 'https://cnd.pdm.dev/public/images/nataniel.jpg',
    cellphone: '+55997881558',
    phone: '+55997881558',
    birthdate: '05/12/1993',
    status: 'Ativo',
    address: {
      landmark: 'ponto de referencia aqui',
      zipCode: '97.547-583',
      street: 'Rua Marechal Floriano',
      number: '1547',
      neighborhood: 'Cidade Alta',
      complements: 'Logo alí',
      city: 'Alegrete',
      state: 'RS',
    },
  },
  {
    id: '2',
    type: 'INSTRUCTOR',
    userName: 'nataniel@pedimos.com.br',
    name: 'Michael Nazaré Martins',
    avatar: 'https://cnd.pdm.dev/public/images/nataniel.jpg',
    cellphone: '+55997881558',
    phone: '+55997881558',
    birthdate: '05/12/1993',
    status: 'Ativo',
    address: {
      landmark: 'ponto de referencia aqui',
      zipCode: '97.547-583',
      street: 'Rua Marechal Floriano',
      number: '1547',
      neighborhood: 'Cidade Alta',
      complements: 'Logo alí',
      city: 'Alegrete',
      state: 'RS',
    },
  },
  {
    id: '3',
    type: 'STUDENT',
    registration: '554884414',
    name: 'Nataniel de Mello Fogiatto',
    socialName: 'Natazin',
    email: 'nataniel@pedimos.com.br',
    cellphone: '+5533994230888',
    avatar: 'https://cnd.pdm.dev/public/users/aluno/xzysdf.png',
    responsibles: [
      {
        id: '1',
        name: 'Marilene da Silva',
        kinship: 'mother',
        cellphone: '4002-8922',
      },
      {
        id: '2',
        name: 'Jandira Geovana',
        kinship: 'mother',
        cellphone: '4002-8922',
      },
    ],
    documents: {
      rg: '545154151',
      issuer: 'SSP',
      issuerDate: '2015-02-12T00:00:00.000',
      issuerUf: 'RS',
      cpf: '02225115470',
    },
    address: {
      street: 'Rua Marechal Floriano',
      number: '1547',
      neighborhood: 'Cidade Alta',
      complements: 'Logo alí',
      city: 'Alegrete',
      stateAdress: 'RS',
      landmark: 'perto de xyz',
      zipCode: '5599884',
    },
    birthdayInfo: {
      registration: '2155415151515454',
      registry: 'Cartório da cidade',
      nationality: 'Brasileira',
      naturaless: 'Alegretense',
      stateBirth: 'RS',
      dateBirth: '1993-05-12T00:00:00.000',
      gender: 'non-binary',
    },
    note: 'asdfasdfsdf',
    status: 'Ativo',
  },
  {
    id: '4',
    type: 'STUDENT',
    registration: '773158794',
    name: 'Ayrton Senna da Silva',
    socialName: 'Senna',
    email: 'ayrton.senna@pedimos.com.br',
    cellphone: '+55987654321',
    avatar: 'https://cnd.pdm.dev/public/users/aluno/xzysdf.png',
    responsibles: [
      {
        id: '1',
        name: 'Milton Guirado Theodoro Da Silva ',
        kinship: 'father',
        cellphone: '4002-8922',
      },
      {
        id: '2',
        name: 'Neyde Joanna Senna Da Silva',
        kinship: 'mother',
        cellphone: '4002-8922',
      },
    ],
    documents: {
      rg: '545154151',
      issuer: 'SSP',
      issuerDate: '2015-02-12T00:00:00.000',
      issuerUf: 'RS',
      cpf: '02225115470',
    },
    address: {
      street: 'Rua A',
      number: '1234',
      neighborhood: 'Jardim São Paulo',
      complements: 'Logo alí',
      city: 'São Paulo',
      state: 'SP',
      landmark: 'perto de xyz',
      zipcode: '0123456',
    },
    birthdayInfo: {
      registration: '2155415151515454',
      registry: 'Cartório da cidade',
      nationality: 'Brasileira',
      naturaless: 'Paulista',
      stateBirth: 'SP',
      dateBirth: '21/03/1960',
      gender: null,
    },
    note: 'asdfasdfsdf',
    status: 'Inativo',
  },
  {
    id: 'e3628dad-7dae-48bd-800c-6e739c2dcfff',
    type: 'STUDENT',
    studentRegistrationNumber: '2301000105',
    name: 'Gustavo da Frutinha',
    socialName: 'Frutinha',
    email: 'student89@example.com',
    cellphone: '+5533925632555',
    phone: '+5533925632555',
    responsibles: [
      {
        id: '69b54e5b-cfbb-430d-9853-adebb5e00694',
        name: 'string',
        kinship: 'mother',
        cellphone: '+5533925632555',
        occupation: 'string',
        email: 'user@example.com',
        postalCode: '83148204',
        place: 'string',
        number: '6',
        neighborhood: 'string',
        city: 'string',
        state: 'AC',
        complement: 'string',
      },
    ],
    mainResponsibleId: '69b54e5b-cfbb-430d-9853-adebb5e00694',
    documents: {
      identityDocument: '0000000',
      issuer: 'string',
      issuerDate: '2023-01-10',
      issuerState: 'AC',
      taxNumber: '11579408621',
    },
    address: {
      place: 'string',
      number: '8',
      complement: 'string',
      neighborhood: 'string',
      city: 'string',
      state: 'AC',
      landmark: 'string',
      postalCode: '20606-448',
    },
    birthdateInfo: {
      birthCertificateNumber: 'stringstringstringstringstringst',
      notaryOffice: 'string',
      nationality: 'string',
      naturalness: 'string',
      birthState: 'AC',
      birthdate: '2023-01-10',
      gender: 'non-binary',
    },
    note: 'string',
  },
  {
    id: '831613b9-bfd6-480c-9678-1d92816c3c32',
    type: 'INSTRUCTOR',
    name: 'string',
    email: 'instructor@pdm.dev',
    socialName: 'string',
    birthdate: '2023-01-10',
    cellphone: '+5533925632555',
    phone: '+5533925632555',
    address: {
      place: 'string',
      number: '1',
      complement: 'string',
      neighborhood: 'string',
      city: 'string',
      state: 'AC',
      landmark: 'string',
      postalCode: '36632-966',
    },
  },
];

api.onGet('/api/users').reply((request) => {
  const { params } = request;

  if (params.type) {
    const usersByType = usersDB.filter(({ type }) => type === params.type);

    return [200, usersByType];
  }

  return [200, usersDB];
});

api.onPost('/api/users').reply((request) => {
  const data = JSON.parse(request.data);

  const newInstructor = {
    id: generateID(),
    ...data,
  };

  usersDB = [...usersDB, newInstructor];

  return [201, newInstructor];
});

const users = usersDB;

export default users;
