import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const eventsCalendar = {
  events: lazy(() => import('views/eventsCalendar/CalendarPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const eventsCalendarRoute = {
  path: `${appRoot}/calendario`,
  exact: true,
  icon: 'calendar',
  label: 'menu.calendar',
  component: eventsCalendar.events,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/calendario`,
};

export default eventsCalendarRoute;
