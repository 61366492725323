import generateID from 'shared/utils/generateID';
import api from '../../api';

let curriculumComponentDB = [
  {
    id: 1,
    name: 'Língua Portuguesa',
    status: 'Ativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 2,
    name: 'Educação Física',
    status: 'Ativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 3,
    name: 'Redação',
    status: 'Inativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 4,
    name: 'Língua Espanhola',
    status: 'Inativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 5,
    name: 'Língua Inglesa',
    status: 'Inativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 6,
    name: 'Literatura',
    status: 'Inativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 7,
    name: 'Arte',
    status: 'Inativo',
    knowledgeArea: 'Linguagens',
  },
  {
    id: 8,
    name: 'Matemática',
    status: 'Ativo',
    knowledgeArea: 'Matemática',
  },
  {
    id: 9,
    name: 'Física',
    status: 'Ativo',
    knowledgeArea: 'Ciências da Natureza',
  },
  {
    id: 10,
    name: 'Química',
    status: 'Inativo',
    knowledgeArea: 'Ciências da Natureza',
  },
  {
    id: 11,
    name: 'Biologia',
    status: 'Inativo',
    knowledgeArea: 'Ciências da Natureza',
  },
  {
    id: 12,
    name: 'Filosofia/Ensino Religioso',
    status: 'Ativo',
    knowledgeArea: 'Ciências Humanas',
  },
  {
    id: 13,
    name: 'Cooperativismo/Empreendedorismo',
    status: 'Ativo',
    knowledgeArea: 'Itinerário Formativo',
  },
  {
    id: 14,
    name: 'Projeto de Vida',
    status: 'Inativo',
    knowledgeArea: 'Itinerário Formativo',
  },
  {
    id: 15,
    name: 'Oficinas de Texto',
    status: 'Inativo',
    knowledgeArea: 'Itinerário Formativo',
  },
  {
    id: 16,
    name: 'Cultura Digital',
    status: 'Ativo',
    knowledgeArea: 'Itinerário Formativo',
  },
  {
    id: 17,
    name: 'Acompanhamento de Aprendizagem',
    status: 'Inativo',
    knowledgeArea: 'Itinerário Formativo',
  },
  {
    id: 18,
    name: 'Núcleos de estudos',
    status: 'Inativo',
    knowledgeArea: 'Itinerário Formativo',
  },
];

api.onGet('/api/education-basic/curriculum-component').reply(() => {
  return [200, curriculumComponentDB];
});

api.onPost('/api/education-basic/curriculum-component').reply((request) => {
  const data = JSON.parse(request.data);

  const newCurriculumComponent = {
    ...data,
    id: generateID(),
  };

  curriculumComponentDB = [...curriculumComponentDB, newCurriculumComponent];

  return [201, newCurriculumComponent];
});

export const curriculum = curriculumComponentDB;
