const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export default function checkToken() {
  const token = localStorage.getItem('pdmAuthService:accessToken');

  if (token) {
    const decodedJwt = parseJwt(token);
    if (!decodedJwt) {
      return false;
    }

    return decodedJwt.exp * 1000 < Date.now();
  }

  return false;
}
