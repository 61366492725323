import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const registration = {
  registration: lazy(() => import('views/registration/RegistrationPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const registrationRoute = {
  path: `${appRoot}/matricula`,
  exact: true,
  icon: 'grid-1',
  label: 'menu.registration',
  component: registration.registration,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/matricula`,
};

export default registrationRoute;
