const lessonPlansPtMessages = {
  'lesson-plans.add-curriculum-component': 'Adicionar Componente Curricular',
  'lesson-plans.add-instructor': 'Adicionar Professor',
  'lesson-plans.add-lesson-plan': 'Adicionar Novo Plano de Aula',
  'lesson-plans.add-new-time': 'Adicionar Novo Horário',
  'lesson-plans.add-recurrence': 'Adicionar Recorrência',
  'lesson-plans.filters-applied': 'Filtros aplicados:',
  'lesson-plans.filter-by-accomplish': 'Filtrar por lançamento de aulas',
  'lesson-plans.filter-by-attendance': 'Filtrar por chamada',
  'lesson-plans.add-sub-curriculum-component':
    'Adicionar Sub Componente Curricular',
  'lesson-plans.advice-about-finish-lesson-plan':
    'Após a confirmação será gerada as aulas para essa turma conforme as recorrências cadastradas.',
  'lesson-plans.advice-about-recorrences':
    'Não será possível realizar alterações nas recorrências após a confirmação.',

  'lesson-plans.change-recorrence-text':
    'Essa alteração se aplicará às aulas planejadas a partir da data informada.',
  'lesson-plans.class-development': 'Desenvolvimento da Aula',
  'lesson-plans.confirm-all-text':
    'Esta ação irá replicar o conteúdo desta aula para todas as demais neste dia.',
  'lesson-plans.content-registration': 'Registro de Conteúdo',
  'lesson-plans.create-lesson-plan': 'Criar Plano de Aula',

  'lesson-plans.edit-lesson-plan': 'Editar Plano de Aula',
  'lesson-plans.edit-time': 'Editar Horário',
  'lesson-plans.empty-text': 'Você ainda não tem nenhum plano de aula criado',

  'lesson-plans.filter-text': 'Filtrar por um intervalo de datas.',
  'lesson-plans.finish-lesson-plans': 'Finalizar Planos de Aula',
  'lesson-plans.finish-lesson-plans-tootip':
    'Cadastre as recorrências antes de finalizar',
  'lesson-plans.finished-lesson-plans': 'Planos de aula finalizado',

  'lesson-plans.init-data-text':
    'Selecione a {grade} para buscar os planos de aula',

  'lesson-plans.lesson-change-text':
    'Selecione o componente curricular e a data da aula em que você deseja realizar a troca.',

  'lesson-plans.no-lesson': 'Nenhuma aula planejada encontrada.',

  'lesson-plans.recurrence-edit-title': 'Alterar Recorrência',
  'lesson-plans.replicate-lesson': 'Replicar Aula',

  'lesson-plans.show-lessons': 'Exibir Aulas',
  'lesson-plans.show-lessons-tooltip':
    'Disponível após finalizar o plano de aula',

  'lesson-plans.time-interval': '{start} às {end}',

  'lesson-plans.used-material': 'Material Usado',

  // lesson filter
  'lesson-plans.ACCOMPLISH': 'Aulas lançadas',
  'lesson-plans.NOT_ACCOMPLISH': 'Aulas não lançadas',
  'lesson-plans.ATTENDANCE': 'Chamada lançada',
  'lesson-plans.NOT_ATTENDANCE': 'Chamada não lançada',
};

export default lessonPlansPtMessages;
