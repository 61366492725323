import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import api from 'services/api';

export const getCurriculumMatrices = createAsyncThunk(
  'curriculumMatrix/getCurriculumMatrices',
  async (gradeId) => {
    const response = await api.get('curriculum-matrix', {
      params: { gradeId },
    });
    const { data } = await response.data;
    return data;
  }
);

export const createCurriculumMatrix = createAsyncThunk(
  'curriculumMatrix/createCurriculumMatrix',
  async (curriculumMatrix) => {
    try {
      const response = await api.post('curriculum-matrix', {
        ...curriculumMatrix,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editCurriculumMatrix = createAsyncThunk(
  'curriculumMatrix/editCurriculumMatrix',
  async ({ updatedMatrix, id }) => {
    try {
      const response = await api.patch(`curriculum-matrix/${id}`, {
        ...updatedMatrix,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const deleteCurriculumMatrix = createAsyncThunk(
  'curriculumMatrix/deleteCurriculumMatrix',
  async (id) => {
    try {
      const response = await api.delete(`curriculum-matrix/${id}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const curriculumMatrixAdapter = createEntityAdapter();

export const { selectAll: selectCurriculumMatrix } =
  curriculumMatrixAdapter.getSelectors((state) => state.curriculumMatrix);

const curriculumMatrixSlice = createSlice({
  name: 'curriculumMatrix',
  initialState: curriculumMatrixAdapter.getInitialState({
    isLoading: false,
    gradeTrainingTypes: {},
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getGradeTrainingTypes: (state, { payload }) => {
      const gradeTrainingTypes = payload?.grade?.trainingTypes.reduce(
        (prev, trainingType) => {
          prev[trainingType?.id] = {
            trainingType,
            matrices: [],
          };

          return prev;
        },
        {}
      );

      state.gradeTrainingTypes = gradeTrainingTypes || {};
    },
    addCurriculumMatrix: (state, { payload }) => {
      state.gradeTrainingTypes[payload].matrices = [
        ...state.gradeTrainingTypes[payload].matrices,
        { curriculumComponent: '', hour: '', id: '' },
      ];
    },
  },
  extraReducers: {
    [getCurriculumMatrices.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurriculumMatrices.fulfilled]: (state, { payload }) => {
      curriculumMatrixAdapter.setAll(state, payload);

      state.gradeTrainingTypes = Object.entries(
        state.gradeTrainingTypes
      ).reduce((prev, [trainingTypeId]) => {
        const filteredMatrices = payload.filter(
          ({ trainingType }) => trainingType.id === trainingTypeId
        );

        return {
          ...prev,
          [trainingTypeId]: {
            ...prev[trainingTypeId],
            matrices:
              filteredMatrices.length > 0
                ? filteredMatrices
                : [{ curriculumComponent: '', hour: '', id: '' }],
          },
        };
      }, state.gradeTrainingTypes);

      state.isLoading = false;
    },
    [getCurriculumMatrices.rejected]: (state) => {
      state.isLoading = false;
    },

    [createCurriculumMatrix.pending]: (state) => {
      state.isLoading = true;
    },
    [createCurriculumMatrix.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        curriculumMatrixAdapter.addOne(state, payload.data);

        state.gradeTrainingTypes[payload.data.trainingType.id].matrices = [
          ...state.gradeTrainingTypes[
            payload.data.trainingType.id
          ].matrices.filter(({ id }) => Boolean(id)),
          payload.data,
        ];
      }
    },
    [createCurriculumMatrix.rejected]: (state) => {
      state.isLoading = false;
    },

    [editCurriculumMatrix.pending]: (state) => {
      state.isLoading = true;
    },
    [editCurriculumMatrix.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        curriculumMatrixAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });

        state.gradeTrainingTypes[payload.data.trainingType.id].matrices = [
          ...state.gradeTrainingTypes[
            payload.data.trainingType.id
          ].matrices.filter(({ id }) => payload.data.id !== id),
          payload.data,
        ];
      }
    },
    [editCurriculumMatrix.rejected]: (state) => {
      state.isLoading = false;
    },

    [deleteCurriculumMatrix.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCurriculumMatrix.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.success) {
        curriculumMatrixAdapter.removeOne(state, payload.data.id);

        state.gradeTrainingTypes[payload.data.trainingType.id].matrices = [
          ...state.gradeTrainingTypes[
            payload.data.trainingType.id
          ].matrices.filter(({ id }) => id !== payload.data.id),
        ];
      }
    },
    [deleteCurriculumMatrix.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setLoading, addCurriculumMatrix, getGradeTrainingTypes } =
  curriculumMatrixSlice.actions;

export default curriculumMatrixSlice.reducer;
