import api from '../../api';

const response = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  rowsPerPage: 20,
  totalPages: 1,
  totalRowCount: 3,
  data: [
    {
      id: 1,
      name: '1° Ano',
      type: 'MARK',
      highest: 100.0,
      average: 60.0,
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino M\u00e9dio',
        type: 'TRIMESTER',
        isActive: true,
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
        academicTerms: [],
      },
      trainingTypes: [
        { id: 1, name: 'Formação Geral Básica', initials: 'IGB' },
        { id: 2, name: 'Itinerário Formativo Integrado', initials: 'IFI' },
        { id: 3, name: 'Itinerário Formativo de Área', initials: 'IFA' },
      ],
      academicTermMarks: [],
    },

    {
      id: 2,
      name: '2° Ano',
      type: 'MARK',
      highest: 100.0,
      average: 60.0,
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino M\u00e9dio',
        type: 'TRIMESTER',
        isActive: true,
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
        academicTerms: [
          {
            id: '12d',
            start: '2023-07-06',
            end: '2023-07-09',
          },
          {
            id: '13t',
            start: '2023-07-10',
            end: '2023-07-11',
          },
          {
            id: '14h',
            start: '2023-07-10',
            end: '2023-07-11',
          },
        ],
      },
      trainingTypes: [
        { id: 1, name: 'Formação Geral Básica', initials: 'IGB' },
        { id: 2, name: 'Itinerário Formativo Integrado', initials: 'IFI' },
        { id: 3, name: 'Itinerário Formativo de Área', initials: 'IFA' },
      ],
      academicTermMarks: [
        {
          academicTerm: { id: '12d' },
          highest: 10,
        },
        {
          academicTerm: { id: '13t' },
          highest: 10,
        },
        {
          academicTerm: { id: '14h' },
          highest: 20,
        },
      ],
    },

    {
      id: 3,
      name: '3° Ano',
      type: 'MARK',
      highest: 100.0,
      average: 60.0,
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino M\u00e9dio',
        type: 'QUARTER',
        isActive: true,
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
        academicTerms: [
          {
            id: '213',
            start: '2023-07-06',
            end: '2023-07-09',
          },
          {
            id: '214',
            start: '2023-07-10',
            end: '2023-07-11',
          },
          {
            id: '215',
            start: '2023-07-10',
            end: '2023-07-11',
          },
          {
            id: '216',
            start: '2023-07-10',
            end: '2023-07-11',
          },
        ],
      },
      trainingTypes: [],
      academicTermMarks: [],
    },

    {
      id: 4,
      name: '1° Ano',
      type: 'ASSESSMENT',
      highest: null,
      average: null,
      educationLevel: {
        id: 'e701a450-b9bf-11ed-96d3-f5fbc681ce13',
        name: 'Educa\u00e7\u00e3o Infantil',
        type: 'SEMESTER',
        isActive: true,
        academicYear: {
          id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
          year: '2023',
          start: '2023-02-01',
          end: '2023-12-17',
        },
        academicTerms: [],
      },
      trainingTypes: [],
      academicTermMarks: [],
    },
  ],
};

api.onGet('grade').reply(() => {
  return [200, response];
});
