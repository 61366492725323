import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

const EMPTY_RECURRENCE = {
  weekday: '',
  start: '',
  end: '',
  id: 'new',
  childLessonPlanRecurrence: null,
};

export const getRecurrences = createAsyncThunk(
  'recurrences/getRecurrences',
  async (lessonPlanId) => {
    const response = await api.get('lesson-plan-recurrence', {
      params: { lessonPlanId },
    });
    const { data } = await response.data;

    return data;
  }
);

export const getRecurrencesByGrade = createAsyncThunk(
  'recurrences/getRecurrencesByGrade',
  async (gradeId) => {
    const response = await api.get('lesson-plan-recurrence', {
      params: { gradeId },
    });
    const { data } = await response.data;

    return data;
  }
);

export const createRecurrence = createAsyncThunk(
  'recurrences/createRecurrence',
  async (recurrence) => {
    try {
      const response = await api.post('lesson-plan-recurrence', {
        ...recurrence,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editRecurrence = createAsyncThunk(
  'recurrences/editRecurrence',
  async (values) => {
    try {
      const response = await api.patch(
        `lesson-plan-recurrence/${values.recurrence.id}`,
        {
          ...values,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const removeRecurrence = createAsyncThunk(
  'recurrences/removeRecurrence',
  async (recurrenceId) => {
    try {
      const response = await api.delete(
        `lesson-plan-recurrence/${recurrenceId}`
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const createRecurrenceChild = createAsyncThunk(
  'recurrences/createRecurrenceChild',
  async (recurrenceChild) => {
    try {
      const response = await api.post('lesson-plan-recurrence/child', {
        ...recurrenceChild,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const recurrenceAdapter = createEntityAdapter({});

export const {
  selectAll: selectRecurrences,
  selectEntities: selectRecurrencesEntities,
  selectById: selectRecurrenceById,
} = recurrenceAdapter.getSelectors((state) => state.recurrences);

const recurrencesSlice = createSlice({
  name: 'recurrences',
  initialState: recurrenceAdapter.getInitialState({
    isLoading: false,
    recurrencesByGrade: [],
    recurrenceModal: {
      show: false,
      data: null,
    },
  }),
  reducers: {
    addRecurrenceForm: (state) => {
      recurrenceAdapter.addOne(state, EMPTY_RECURRENCE);
    },

    closeRecurrenceModal: (state) => {
      state.recurrenceModal = { data: null, show: false, type: 'new' };
    },
    openEditRecurrenceModal: (state, { payload }) => {
      state.recurrenceModal = {
        data: payload,
        show: true,
      };
    },
  },
  extraReducers: {
    [getRecurrences.pending]: (state) => {
      state.isLoading = true;
    },
    [getRecurrences.fulfilled]: (state, { payload }) => {
      recurrenceAdapter.setAll(state, payload);
      if (payload.length === 0) {
        recurrenceAdapter.addOne(state, EMPTY_RECURRENCE);
      }
      state.isLoading = false;
    },
    [getRecurrences.rejected]: (state) => {
      state.isLoading = false;
    },

    [getRecurrencesByGrade.fulfilled]: (state, { payload }) => {
      state.recurrencesByGrade = payload;
    },

    [createRecurrence.pending]: (state) => {
      state.isLoading = true;
    },
    [createRecurrence.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        recurrenceAdapter.addOne(state, payload.data);
        recurrenceAdapter.removeOne(state, 'new');
        state.recurrencesByGrade = [...state.recurrencesByGrade, payload.data];
      }
      return state;
    },
    [createRecurrence.rejected]: (state) => {
      state.isLoading = false;
    },

    [editRecurrence.pending]: (state) => {
      state.isLoading = true;
    },
    [editRecurrence.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.success) {
        recurrenceAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
      return state;
    },
    [editRecurrence.rejected]: (state) => {
      state.isLoading = false;
    },

    [removeRecurrence.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        recurrenceAdapter.removeOne(state, payload.data.id);
        state.recurrencesByGrade = state.recurrencesByGrade.filter(
          ({ id }) => id !== payload.data.id
        );
      }
      return state;
    },
  },
});

export const {
  addRecurrenceForm,
  closeRecurrenceModal,
  openEditRecurrenceModal,
} = recurrencesSlice.actions;

export default recurrencesSlice.reducer;
