import generateID from 'shared/utils/generateID';
import api from '../../api';

let eventsCalendarDB = [
  {
    id: '6umdjqgf',
    year: 2022,
    status: 'Ativo',
    start: '2022-01-01T00:00:00.0000',
    end: '2022-12-31T00:00:00.0000',
  },
  {
    id: '3v1pg9ke',
    year: 2022,
    status: 'Inválido',
    start: '2022-01-01T00:00:00.0000',
    end: '2022-07-31T00:00:00.0000',
  },
  {
    id: '4utgxzau',
    year: 2023,
    status: 'Ativo',
    start: '2022-01-01T00:00:00.0000',
    end: '2022-07-31T00:00:00.0000',
  },
];

api.onGet('/api/calendar').reply((request) => {
  const { params } = request;

  if (params.year) {
    const filteredCalendar = eventsCalendarDB.filter(
      (calendar) => calendar.year === params.year
    );

    return [200, filteredCalendar];
  }

  return [200, eventsCalendarDB];
});

api.onPost('/api/calendar').reply((request) => {
  const data = JSON.parse(request.data);

  const newEvent = {
    id: generateID(),
    year: data.year,
    status: 'Ativo',
    start: data.events[0].start,
    end: data.events[data.events.length - 1].end,
  };

  eventsCalendarDB = [...eventsCalendarDB, newEvent];

  return [201, eventsCalendarDB];
});

api.onGet('/api/calendar/years').reply(() => {
  const allYears = eventsCalendarDB.map((calendar) => calendar.year);

  const uniqYears = [...new Set(allYears)];

  const response = uniqYears
    .map((year) => ({
      value: year,
      label: year.toString(),
    }))
    .sort((firstYear, secondYear) => firstYear.value - secondYear.value);

  return [200, response];
});
