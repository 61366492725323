import api from '../../api';

const response = {
  data: [
    {
      id: '02bd93fe-c8fd-4b18-b93f-36eadc293de7',
      score: 7,
      schoolEvaluation: {
        id: '2c395e28-0df4-4560-b371-37e3c24b75df',
        name: 'Teste 1',
        description: 'any-description',
        type: 'WORK',
        highest: 100,
        date: '2023-07-24',
        academicTerm: {
          id: '45b31149-6405-44cb-b9dc-d6adde6f46d7',
          start: '2023-01-01',
          end: '2023-02-01',
        },
        curriculumComponent: {
          id: '5fdc3a0a-1944-497a-830d-59302aa36e2b',
          name: 'Componente Curricular 1',
          isActive: true,
          knowledgeArea: {
            id: '83f5ba44-fd3e-4947-a55e-dde0af224193',
            name: 'Área de Conhecimento 1',
            isActive: true,
          },
        },
        grade: {
          id: 'b75a5a5c-1a9a-4a84-8fc7-f9240591adec',
          name: 'Notas 1',
          type: 'MARK',
          highest: 100,
          average: 60,
          trainingTypes: [
            {
              id: '7f39717d-a349-4396-8d4a-6b187551b363',
              name: 'Tipo de Ensino 1',
              initials: 'TDE1',
            },
          ],
          educationLevel: {
            id: '0e15ccd0-1582-4bd6-aa21-5a5f109c6353',
            name: 'Nível de Ensino 1',
            isActive: true,
            type: 'TRIMESTER',
            academicTerms: [
              {
                id: '45b31149-6405-44cb-b9dc-d6adde6f46d7',
                start: '2023-01-01',
                end: '2023-02-01',
              },
              {
                id: '01269a82-81a2-4319-af9b-711786dc5e98',
                start: '2023-03-01',
                end: '2023-04-01',
              },
              {
                id: 'c58365e7-8915-4cf4-abc7-46513a5a2fdc',
                start: '2023-05-01',
                end: '2023-06-01',
              },
            ],
          },
          academicYear: {
            id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
            year: '2023',
          },
        },
      },
      studentEnrollment: {
        id: '3710aade-0e0b-4182-8955-46af7885f9e7',
        user: {
          id: 'b1f12882-ddcd-4b2f-bb53-01d39906566a',
          name: 'StUdEnT 1 - XxX',
          studentRegistrationNumber: '2304000100',
        },
        status: 'string',
      },
    },
    {
      id: null,
      score: null,
      schoolEvaluation: {
        id: '2c395e28-0df4-4560-b371-37e3c24b75df',
        name: 'Teste 1',
        description: 'any-description',
        type: 'WORK',
        highest: 100,
        date: '2023-07-24',
        academicTerm: {
          id: '45b31149-6405-44cb-b9dc-d6adde6f46d7',
          start: '2023-01-01',
          end: '2023-02-01',
        },
        curriculumComponent: {
          id: '5fdc3a0a-1944-497a-830d-59302aa36e2b',
          name: 'Componente Curricular 1',
          isActive: true,
          knowledgeArea: {
            id: '83f5ba44-fd3e-4947-a55e-dde0af224193',
            name: 'Área de Conhecimento 1',
            isActive: true,
          },
        },
        grade: {
          id: 'b75a5a5c-1a9a-4a84-8fc7-f9240591adec',
          name: 'Notas 1',
          type: 'MARK',
          highest: 100,
          average: 60,
          trainingTypes: [
            {
              id: '7f39717d-a349-4396-8d4a-6b187551b363',
              name: 'Tipo de Ensino 1',
              initials: 'TDE1',
            },
          ],
          educationLevel: {
            id: '0e15ccd0-1582-4bd6-aa21-5a5f109c6353',
            name: 'Nível de Ensino 1',
            isActive: true,
            type: 'TRIMESTER',
            academicTerms: [
              {
                id: '45b31149-6405-44cb-b9dc-d6adde6f46d7',
                start: '2023-01-01',
                end: '2023-02-01',
              },
              {
                id: '01269a82-81a2-4319-af9b-711786dc5e98',
                start: '2023-03-01',
                end: '2023-04-01',
              },
              {
                id: 'c58365e7-8915-4cf4-abc7-46513a5a2fdc',
                start: '2023-05-01',
                end: '2023-06-01',
              },
            ],
          },
          academicYear: {
            id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
            year: '2023',
          },
        },
      },
      studentEnrollment: {
        id: '3710aade-0e0b-4182-8955-46af7885f9e7',
        user: {
          id: 'b1f12882-ddcd-4b2f-bb53-01d39906566a',
          name: 'StUdEnT 2 - XxX',
          studentRegistrationNumber: '2304000100',
        },
        status: 'string',
      },
    },
  ],
};

api.onGet('student-evaluation').reply(() => {
  return [200, response];
});

api.onPost('student-evaluation').reply(() => {
  return [200, { ...response.data[1], score: 77.7, id: 2 }];
});
