import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';

const initialState = {
  status: 'idle',
  items: [],
  selectedYear: {},
};

const menuYearSlice = createSlice({
  name: 'menuYear',
  initialState,
  reducers: {
    menuYearLoading(state) {
      state.status = 'loading';
    },

    menuYearLoaded(state, { payload }) {
      const currentYear = new Date().getFullYear().toString();

      if (payload.isLogin) {
        const currentAcademicYear = payload.years.find(
          ({ year }) => year === currentYear
        );

        state.selectedYear = currentAcademicYear;
      }

      state.items = payload.years;
      state.status = 'idle';
    },

    changeYear(state, { payload }) {
      state.selectedYear = payload;
    },
  },
});

export const { menuYearLoading, menuYearLoaded, changeYear } =
  menuYearSlice.actions;

export const fetchYears =
  ({ isLogin = false }) =>
  async (dispatch) => {
    dispatch(menuYearLoading());

    const response = await api.get('academic-year');

    dispatch(menuYearLoaded({ years: response.data.data, isLogin }));
  };

const menuYearReducer = menuYearSlice.reducer;
export default menuYearReducer;
