import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    reportModal: {
      show: false,
      reports: [],
      lessonPlan: null,
      studentEnrollment: null,
      grade: null,
    },
  },
  reducers: {
    openReportModal: (state, { payload }) => {
      state.reportModal = { show: true, ...payload };
    },
    closeReportModal: (state) => {
      state.reportModal = {
        show: false,
        lessonPlan: null,
        studentEnrollment: null,
      };
    },
  },
});

export const { openReportModal, closeReportModal } = reportSlice.actions;

export default reportSlice.reducer;
