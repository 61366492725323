import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import api from 'services/api';
import difference from 'shared/utils/difference';

export const getInstructors = createAsyncThunk(
  'instructors/getInstructors',
  async ({ currentPage, searchFor }, { getState }) => {
    const {
      instructors: { pagination },
    } = getState();
    const response = await api.get('/user', {
      params: {
        type: 'INSTRUCTOR',
        currentPage: currentPage ?? pagination.currentPage,
        searchFor,
      },
    });
    return response.data;
  }
);

export const getInstructorById = createAsyncThunk(
  'instructors/getInstructorById',
  async (instructorId) => {
    try {
      const { data } = await api.get(`/user/${instructorId}`);
      return data;
    } catch (error) {
      console.error('Get Instructor API error: ', error.message);
      return [];
    }
  }
);

export const createInstructor = createAsyncThunk(
  'instructors/createInstructor',
  async (newInstructor) => {
    try {
      const response = await api.post('/user/instructor', {
        ...newInstructor,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      switch (err.response.status) {
        case 422:
          return {
            success: false,
            response: err.response.data,
          };
        default:
          throw err;
      }
    }
  }
);

export const editInstructor = createAsyncThunk(
  'instructors/editInstructor',
  async (instructor, { getState }) => {
    const { instructors } = getState();

    const instructorDiff = difference(
      instructor,
      instructors.selectedInstructor.data
    );

    try {
      const response = await api.patch(`/user/instructor/${instructor.id}`, {
        ...instructorDiff,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      switch (err.response.status) {
        case 422:
          return {
            success: false,
            data: err.response.data,
          };
        default:
          throw err;
      }
    }
  }
);

const instructorsAdapter = createEntityAdapter();

export const { selectAll: selectInstructors } = instructorsAdapter.getSelectors(
  (state) => state.instructors
);

export const ROWS_PER_PAGE_DEFAULT = 20;

const instructorSlice = createSlice({
  name: 'instructors',
  initialState: instructorsAdapter.getInitialState({
    isLoading: false,
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      totalPages: 1,
      totalRowCount: 0,
    },
    selectedInstructor: {
      data: { address: null },
      type: 'new',
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showSelectInstructor: (state, { payload }) => {
      state.selectedInstructor = {
        data: payload,
        type: 'show',
      };
    },
    editSelectInstructor: (state, { payload }) => {
      state.selectedInstructor = {
        data: payload,
        type: 'edit',
      };
    },
    clearInstructors: (state) => {
      instructorsAdapter.setAll(state, []);
    },
  },
  extraReducers: {
    [getInstructors.pending]: (state) => {
      state.isLoading = true;
    },
    [getInstructors.fulfilled]: (state, { payload }) => {
      instructorsAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.isLoading = false;
    },
    [getInstructors.rejected]: (state) => {
      state.isLoading = false;
    },

    [createInstructor.pending]: (state) => {
      state.isLoading = true;
    },

    [createInstructor.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        return instructorsAdapter.addOne(state, payload.data);
      }
      return state;
    },
    [createInstructor.rejected]: (state) => {
      state.isLoading = false;
    },
    [editInstructor.pending]: (state) => {
      state.isLoading = true;
    },
    [editInstructor.fulfilled]: (state, { payload }) => {
      instructorsAdapter.updateOne(state, payload);
      state.isLoading = false;
    },
    [editInstructor.rejected]: (state) => {
      state.isLoading = false;
    },

    [getInstructorById.pending]: (state) => {
      state.isLoading = true;
    },

    [getInstructorById.fulfilled]: (state, { payload }) => {
      state.selectedInstructor.data = payload;
      state.isLoading = false;
    },
    [getInstructorById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  setLoading,
  showSelectInstructor,
  editSelectInstructor,
  clearInstructors,
} = instructorSlice.actions;

export default instructorSlice.reducer;
