const usersPtMessages = {
  'users.add-new-instructor': 'Adicionar novo Professor',
  'users.add-responsible-title':
    'Você deve adicionar pelo menos um responsável para o aluno.',
  'users.additional-information': 'Informações Complementares',
  'users.adress': 'Endereço',
  'users.avenue': 'Avenida',

  'users.birthday': 'Nascimento',
  'users.birth-date': 'Data de Nascimento',

  'users.city': 'Cidade',
  'users.cpf': 'CPF',
  'users.complement': 'Complemento',

  'users.edit-instructor': 'Editar Professor',
  'users.edit-student': 'Editar Estudante',
  'users.edit-responsible': 'Editar Responsável',
  'users.email': 'E-mail',

  'users.gender': 'Gênero',

  'users.id': 'Matrícula',
  'users.info': 'Informações básicas',
  'users.issuer': 'Órgão Emissor',
  'users.issuer-date': 'Data de Emissão',

  'users.notary-office': 'Cartório',
  'users.naturalness': 'Naturalidade',

  'users.kinship': 'Parentesco',

  'users.landmark': 'Ponto de Referência',

  'users.mandatory': 'Obrigatório',

  'users.nationality': 'Nacionalidade',
  'users.naturaless': 'Naturalidade',
  'users.neighborhood': 'Bairro',
  'users.new-instructor': 'novo professor',
  'users.new-student': 'novo aluno',
  'users.new-responsible': 'Adicionar Responsável',

  'users.place': 'Rua',

  'users.occupation': 'Profissão',

  'users.registry': 'Cartório',

  'users.old-student-registration-number': 'Matrícula Antiga',

  'users.responsible': 'Responsável',
  'users.rg': 'RG',

  'users.search-student': 'Pesquisar por um aluno',
  'users.search-instructor': 'Pesquisar por um professor',
  'users.socia-name': 'Nome Social',
  'users.state': 'Estado',
  'users.status': 'Status',
  'users.street': 'Rua',

  'users.training-note': 'Formação',

  'users.student-without-registration':
    'Nenhuma turma cadastrada para esse aluno.',
  'users.trainingNote': 'Formação',
  'users.users': 'Usuários',
};

export default usersPtMessages;
