import generateID from 'shared/utils/generateID';
import api from '../../api';

let knowledgeAreaDB = [
  {
    id: 1,
    name: 'Linguagens',
    status: 'Ativo',
  },
  {
    id: 2,
    name: 'Matemática',
    status: 'Ativo',
  },
  {
    id: 3,
    name: 'Ciências da Natureza',
    status: 'Ativo',
  },
  {
    id: 4,
    name: 'Ciências Humanas',
    status: 'Inativo',
  },
  {
    id: 5,
    name: 'Itinerário Formativo',
    status: 'Inativo',
  },
];

api.onGet('/api/education-basic/knowledge-area').reply(() => {
  return [200, knowledgeAreaDB];
});

api.onPost('/api/education-basic/knowledge-area').reply((request) => {
  const data = JSON.parse(request.data);

  const newKnowledgeArea = {
    ...data,
    id: generateID(),
  };

  knowledgeAreaDB = [...knowledgeAreaDB, newKnowledgeArea];

  return [201, newKnowledgeArea];
});
