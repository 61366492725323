import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getFrequencies = createAsyncThunk(
  'frequencies/getFrequencies',
  async ({ lessonPlanId, lessonId }) => {
    const response = await api.get(
      `lesson-plan/${lessonPlanId}/lesson/${lessonId}/student-attendance`
    );
    const { data } = await response.data;

    return data;
  }
);

export const syncFrequency = createAsyncThunk(
  'frequencies/createFrequency',
  async ({ lessonPlanId, attendances }) => {
    try {
      const studentAttendacesResponses = await Promise.all(
        Object.entries(attendances).map(([lessonId, lessonAttendances]) =>
          api.put(
            `lesson-plan/${lessonPlanId}/lesson/${lessonId}/student-attendance`,
            { attendances: lessonAttendances }
          )
        )
      );

      const allResponseData = studentAttendacesResponses.reduce(
        (responses, { data }) => {
          return [...responses, ...data.data];
        },
        []
      );

      return {
        success: true,
        allResponseData,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const frequencyAdapter = createEntityAdapter({});

export const { selectAll: selectFrequencies } = frequencyAdapter.getSelectors(
  (state) => state.frequencies
);

const frequencySlice = createSlice({
  name: 'frequencies',
  initialState: frequencyAdapter.getInitialState({
    students: [],
  }),
  reducers: {},
  extraReducers: {
    [getFrequencies.fulfilled]: (state, { payload }) => {
      state.students = payload;
    },

    [syncFrequency.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.students = payload.allResponseData;
      }
    },
  },
});

export default frequencySlice.reducer;
