import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';
import { ROWS_PER_PAGE_DEFAULT } from 'shared/constants/rowsPerPage';

export const getEvaluations = createAsyncThunk(
  'evaluations/getEvaluations',
  async ({ currentPage, lessonPlanId }, { getState }) => {
    const {
      evaluations: { pagination },
    } = getState();

    const { data } = await api.get('lesson-plan', {
      params: {
        parentLessonPlanId: lessonPlanId,
      },
    });

    if (data.data.length > 0) {
      const responseParentLessonPlan = await api.get('school-evaluation', {
        params: {
          currentPage: currentPage ?? pagination.currentPage,
          parentLessonPlanId: lessonPlanId,
        },
      });
      return responseParentLessonPlan.data;
    }

    const responseLessonPlan = await api.get('school-evaluation', {
      params: {
        currentPage: currentPage ?? pagination.currentPage,
        lessonPlanId,
      },
    });
    return responseLessonPlan.data;
  }
);

export const getAllEvaluations = createAsyncThunk(
  'evaluations/getAllEvaluations',
  async (
    { lessonPlanId, parentLessonPlanId, currentPage },
    { getState, dispatch }
  ) => {
    const {
      evaluations: { pagination },
    } = getState();
    const response = await api.get('school-evaluation', {
      params: {
        currentPage: currentPage ?? pagination.currentPage,
        lessonPlanId,
        parentLessonPlanId,
      },
    });

    if (response.data.currentPage < response.data.totalPages) {
      dispatch(
        getAllEvaluations({
          lessonPlanId,
          currentPage: response.data.currentPage + 1,
        })
      );
    }

    return response.data;
  }
);

export const createEvaluation = createAsyncThunk(
  'evaluations/createEvaluation',
  async (evaluation) => {
    try {
      const response = await api.post('school-evaluation', {
        ...evaluation,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editEvaluation = createAsyncThunk(
  'evaluations/editEvaluation',
  async (evaluation) => {
    try {
      const response = await api.patch(`school-evaluation/${evaluation.id}`, {
        ...evaluation,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const removeEvaluation = createAsyncThunk(
  'evaluations/removeEvaluation',
  async (id) => {
    try {
      const response = await api.delete(`school-evaluation/${id}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const evaluationsAdapter = createEntityAdapter({});

export const {
  selectAll: selectEvaluations,
  selectEntities: selectEvaluationsEntities,
  selectById: selectEvaluationById,
} = evaluationsAdapter.getSelectors((state) => state.evaluations);

const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState: evaluationsAdapter.getInitialState({
    evaluationModal: {
      show: false,
      type: 'new',
      data: null,
    },
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      totalPages: 1,
      totalRowCount: 0,
    },
    filter: {
      lessonPlanChild: null,
    },
  }),

  reducers: {
    openEvaluationModal: (state) => {
      state.evaluationModal = { show: true, type: 'new', data: null };
    },
    closeEvaluationModal: (state) => {
      state.evaluationModal = { data: null, show: false, type: 'new' };
    },
    openEditEvaluationModal: (state, { payload }) => {
      state.evaluationModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },
    clearEvaluations: (state) => {
      evaluationsAdapter.setAll(state, []);
    },
    changeFilter: (state, { payload }) => {
      state.filter = { ...state.filter, ...payload };
    },
  },

  extraReducers: {
    [getEvaluations.fulfilled]: (state, { payload }) => {
      evaluationsAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.isLoading = false;
    },

    [getAllEvaluations.fulfilled]: (state, { payload }) => {
      evaluationsAdapter.addMany(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.isLoading = false;
    },

    [createEvaluation.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        evaluationsAdapter.addOne(state, {
          ...payload.data,
        });
      }
    },

    [editEvaluation.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        evaluationsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [removeEvaluation.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        evaluationsAdapter.removeOne(state, payload.data.id);
      }
      return state;
    },
  },
});

export const {
  openEvaluationModal,
  closeEvaluationModal,
  openEditEvaluationModal,
  clearEvaluations,
  changeFilter,
} = evaluationsSlice.actions;

export default evaluationsSlice.reducer;
