import api from './api';
import './data/apps.chat';
import './data/apps.contacts';
import './data/apps.events';
import './data/apps.mailbox';
import './data/apps.tasks';
import './data/datatable';
import './data/notifications';
import './data/products';
import './data/users';
import './data/users/users-db';
import './data/basicEducation/trainingTypes-db';
import './data/curriculumMatrix/curriculumMatrix-db';
import './data/basicEducation/series-db';
import './data/basicEducation/knowledgeArea-db';
import './data/basicEducation/curriculumComponent-db';
import './data/basicEducation/educationLevels-db';
import './data/lessonPlans/lessonPlans-db';
import './data/eventsCalendar/eventsCalendar-db';
import './data/registration/studentEnrollment-DB';
import './data/lessonPlans/recurrences-db';
import './data/instructorView/frequency';
import './data/instructorView/evaluation';
import './data/instructorView/studentEvaluation';
import './data/events/events-db';

api.onAny().passThrough();
