import moment from 'moment';
import 'moment/locale/pt-br';

export default function dateFormatter({
  date,
  pattern = 'DD/MM/YYYY',
  currentFormat,
  addValue,
  dateType,
}) {
  if (addValue) {
    return moment(date, currentFormat)
      .locale('pt-br')
      .add(addValue, dateType)
      .format(pattern);
  }
  return moment(date, currentFormat).locale('pt-br').format(pattern);
}
