import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudentRegistration = createAsyncThunk(
  'documents/getStudentRegistration',
  async (studentId, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const { data } = await api.get(
        `/student-enrollment/academic-year/${selectedYear.id}/student/${studentId}`
      );
      return {
        success: true,
        data: data.data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const documentsAdapter = createEntityAdapter({});

const documentsSlice = createSlice({
  name: 'documents',
  initialState: documentsAdapter.getInitialState({
    studentRegistration: {},
  }),

  extraReducers: {
    [getStudentRegistration.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.studentRegistration = payload.data;
      }
    },
  },
});

export default documentsSlice.reducer;
