import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getCurriculumComponent = createAsyncThunk(
  'curriculumComponent/getCurriculumComponent',
  async ({ educationLevelId, currentPage }, { getState }) => {
    const {
      lessons: { pagination },
      menuYear: { selectedYear },
    } = getState();

    try {
      const response = await api.get('curriculum-component', {
        params: {
          educationLevelId,
          academicYearId: selectedYear.id,
          currentPage: currentPage ?? pagination.currentPage,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Curriculum Component API error: ', error.message);
      return [];
    }
  }
);

export const getCurriculumComponentsByGrade = createAsyncThunk(
  'curriculumComponent/getCurriculumComponentsByGrade',
  async (gradeId) => {
    try {
      const { data } = await api.get(`grade/${gradeId}/curriculum-component`);
      return data.data;
    } catch (error) {
      console.error('Curriculum Component API error: ', error.message);
      return [];
    }
  }
);

export const createCurriculumComponent = createAsyncThunk(
  'curriculumComponent/createCurriculumComponent',
  async (curriculumComponent, { getState }) => {
    const { auth } = getState();
    const { educationalInstitutionId } = auth.currentUser;
    try {
      const response = await api.post('curriculum-component', {
        ...curriculumComponent,
        educationalInstitutionId,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      switch (err.response.status) {
        case 422:
          return {
            success: false,
            data: err.response.data,
          };
        default:
          throw err;
      }
    }
  }
);

export const editCurriculumComponent = createAsyncThunk(
  'curriculumComponent/editCurriculumComponent',
  async (curriculumComponent) => {
    try {
      const response = await api.patch(
        `curriculum-component/${curriculumComponent.id}`,
        {
          ...curriculumComponent,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const curriculumComponentAdapter = createEntityAdapter({});

export const {
  selectAll: selectCurriculumComponent,
  selectEntities: selectCurriculumComponentEntities,
  selectById: selectCurriculumComponentById,
} = curriculumComponentAdapter.getSelectors(
  (state) => state.curriculumComponent
);

const curriculumComponentSlice = createSlice({
  name: 'curriculumComponent',
  initialState: curriculumComponentAdapter.getInitialState({
    isLoading: false,
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      totalPages: 1,
      totalRowCount: 0,
    },

    curriculumComponentModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    openCurriculumComponentModal: (state) => {
      state.curriculumComponentModal = { show: true, type: 'new', data: null };
    },

    closeCurriculumComponentModal: (state) => {
      state.curriculumComponentModal = { data: null, show: false, type: 'new' };
    },

    openEditCurriculumComponentModal: (state, { payload }) => {
      state.curriculumComponentModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },
  },
  extraReducers: {
    [getCurriculumComponent.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        curriculumComponentAdapter.setAll(state, payload.data);
      } else {
        curriculumComponentAdapter.addMany(state, payload.data);
      }
      state.isLoading = false;
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
    },

    [getCurriculumComponentsByGrade.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurriculumComponentsByGrade.fulfilled]: (state, { payload }) => {
      curriculumComponentAdapter.setAll(state, payload);
      state.isLoading = false;
    },
    [getCurriculumComponentsByGrade.rejected]: (state) => {
      state.isLoading = false;
    },

    [createCurriculumComponent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        curriculumComponentAdapter.addOne(state, payload.data);
      }
    },

    [editCurriculumComponent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        curriculumComponentAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },
  },
});

export const {
  setLoading,
  openCurriculumComponentModal,
  closeCurriculumComponentModal,
  openEditCurriculumComponentModal,
} = curriculumComponentSlice.actions;

export default curriculumComponentSlice.reducer;
