import generateID from 'shared/utils/generateID';
import api from '../../api';

let educationLevelsDB = {
  currentPage: 1,
  nextPage: null,
  previousPage: null,
  rowsPerPage: 20,
  totalPages: 1,
  totalRowCount: 4,
  data: [
    {
      id: 1,
      name: 'Educação Infantil',
      isActive: true,
      academicYear: {
        id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
        year: '2023',
        start: '2023-02-01',
        end: '2023-12-17',
      },
      type: 'SEMESTER',
      academicTerms: [],
    },
    {
      id: 2,
      name: 'Ensino Fundamental I',
      isActive: true,
      academicYear: {
        id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
        year: '2023',
        start: '2023-02-01',
        end: '2023-12-17',
      },
      type: 'TRIMESTER',
      academicTerms: [
        {
          start: '2023-02-01',
          end: '2023-04-30',
        },
        {
          start: '2023-05-01',
          end: '2023-08-31',
        },
        {
          start: '2023-09-01',
          end: '2023-12-17',
        },
      ],
    },
    {
      id: 3,
      name: 'Ensino Fundamental II',
      isActive: true,
      academicYear: {
        id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
        year: '2023',
        start: '2023-02-01',
        end: '2023-12-17',
      },
      type: 'TRIMESTER',
      academicTerms: [],
    },
    {
      id: 4,
      name: 'Ensino Médio',
      isActive: true,
      academicYear: {
        id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
        year: '2023',
        start: '2023-02-01',
        end: '2023-12-17',
      },
      type: 'QUARTER',
      academicTerms: [],
    },
  ],
};

api.onGet('education-level').reply(() => {
  return [200, educationLevelsDB];
});

api.onPost('education-level').reply((request) => {
  const data = JSON.parse(request.data);

  const newEducationLevels = {
    ...data,
    id: generateID(),
  };

  educationLevelsDB = [...educationLevelsDB, newEducationLevels];

  return [201, newEducationLevels];
});

export const educationLevels = educationLevelsDB;
