const tourPtMessages = {
  'tour.admin-step-1':
    'No menu lateral você tem acesso a todas as funcionalidades do sistema.',
  'tour.admin-step-2':
    'Antes de iniciar, é importante escolher o ano que será gerenciado no sistema.',
  'tour.admin-step-3':
    'No “Calendário” você pode adicionar e planejar todos os eventos, feriados e reuniões que marcarão o ano letivo, garantindo uma visão abrangente e organizada do cronograma escolar.',
  'tour.admin-step-4':
    'Na seção ”Usuários” você pode gerenciar o cadastro dos professores e alunos de sua instituição.',
  'tour.admin-step-5':
    'Na seção ”Parâmetros”, você tem o controle total sobre todas as informações essenciais para a elaboração dos seus planos de aula.',
  'tour.admin-step-6':
    'Em “Matrícula”, você pode realizar a inclusão dos alunos em suas respectivas turmas.',
  'tour.admin-step-7':
    'Na seção “Planos de Aula”, você pode elaborar os planos de aula para todas as turmas cadastradas.',
  'tour.admin-step-8':
    'Em "Notificações", você pode enviar mensagens para outros usuários.',
  'tour.admin-step-9':
    'Em caso de dúvidas ou necessidade de suporte, não hesite em entrar em contato conosco a qualquer momento. Basta clicar no botão "Precisa de Ajuda?" que lhe auxiliaremos.',
  'tour.admin-step-10':
    'Quando quiser sair do sistema, é simples! Basta clicar no botão "Sair" e pronto.',

  'tour.instructor-step-3':
    'Em "Turmas", você tem a flexibilidade de criar avaliações personalizadas e gerenciar todas as suas aulas.',

  'tour.modal-description':
    'Preparamos para você um tour inicial, nele mostraremos as principais funcionalidades do sistema. Vamos lá?!',

  'tour.init': 'Iniciar',
  'tour.init-tour': 'Iniciar Tour',
};

export default tourPtMessages;
