import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const lessonAdapter = createEntityAdapter({});

const instructorClassesSlice = createSlice({
  name: 'instructorClasses',
  initialState: lessonAdapter.getInitialState({
    isLoading: false,
    selectOptions: {
      year: '',
    },
  }),
  reducers: {
    handleChange: (state, { payload }) => {
      state.selectOptions = { ...state.selectOptions, ...payload };
    },
  },
  extraReducers: {},
});

export const { handleChange } = instructorClassesSlice.actions;

export default instructorClassesSlice.reducer;
