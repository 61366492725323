import generateID from 'shared/utils/generateID';
import api from '../../api';

let trainingTypesDB = [
  { id: 1, name: 'Formação Geral Básica', initials: 'IGB' },
  { id: 2, name: 'Itinerário Formativo Integrado', initials: 'IFI' },
  { id: 3, name: 'Itinerário Formativo de Área', initials: 'IFA' },
];

api.onGet('/api/education-basic/training-types').reply(() => {
  return [200, trainingTypesDB];
});

api.onPost('/api/education-basic/training-types').reply((request) => {
  const data = JSON.parse(request.data);

  const newTrainingType = {
    ...data,
    id: generateID(),
  };

  trainingTypesDB = [...trainingTypesDB, newTrainingType];

  return [201, newTrainingType];
});

export const trainingTypes = trainingTypesDB;
