const registrationPtMessages = {
  'registration.academic-term-mark-not-found':
    'Notas dos períodos ainda não cadastradas.',
  'registration.assessment-report': 'Relatório parecer',
  'registration.alert-error-title': 'Ocorreu um erro!',

  'registration.count-students':
    '{count, plural, =0 {Nenhum aluno matriculado} one {# Discente} other {# Discentes}}',

  'registration.choose-first-class': 'Escolha sua primeira modalidade',
  'registration.choose-second-class': 'Escolha uma segunda modalidade',
  'registration.confirm-first-class':
    'Confirme sua inscrição nessa modalidade. Você ainda poderá escolher uma modalidade secundária.',
  'registration.confirm-second-class':
    'Confirme sua inscrição nessa modalidade.',

  'registration.empty-state-data-text':
    'Clique no botão acima para adicionar um novo aluno',
  'registration.end-registration': 'Fim da Matrícula',

  'registration.frequency-report': 'Relatório de frequência',

  'registration.inform-removal-reason': 'Informe o motivo da remoção',
  'registration.init-state-data-text':
    'Selecione a {grade} para buscar os alunos matriculados',

  'registration.title': 'Matrícula',

  'registration.registration-period': 'Período de matrícula',

  'registration.start-registration': 'Início da Matrícula',
  'registration.student-performance-report': 'Boletim escolar',

  'registration.user-archived': '{user} será arquivado.',

  'registration.registration-completed': 'Cadastro finalizado',
  'registration.registration-completed-message':
    'Você já está matriculado em duas modalidades. Clique aqui e veja os horários.',

  'registration.student-no-vacancy-message':
    'Não temos vaga para a turma escolhida, você ficará em uma fila de espera. Entraremos em contato assim que houver vaga disponível.',
};

export default registrationPtMessages;
