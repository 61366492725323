import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';
import {
  clearEvaluations,
  getAllEvaluations,
} from '../../evaluations/evaluationsSlice';

export const getEvaluationScores = createAsyncThunk(
  'evaluationScores/getEvaluationScores',
  async (schoolEvaluationId) => {
    const response = await api.get(
      `school-evaluation/${schoolEvaluationId}/student-evaluation`
    );
    const { data } = await response.data;

    return data;
  }
);

export const addEvaluationScores = createAsyncThunk(
  'evaluationScores/addEvaluationScores',
  async (schoolEvaluationId) => {
    const response = await api.get(
      `school-evaluation/${schoolEvaluationId}/student-evaluation`
    );
    const { data } = await response.data;

    return data;
  }
);

export const createEvaluationScore = createAsyncThunk(
  'evaluations/createEvaluationScore',
  async ({ schoolEvaluationId, studentEvaluation }) => {
    try {
      const response = await api.post(
        `school-evaluation/${schoolEvaluationId}/student-evaluation`,
        {
          ...studentEvaluation,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editEvaluationScore = createAsyncThunk(
  'evaluations/editEvaluationScore',
  async ({ schoolEvaluationId, studentEvaluation }) => {
    try {
      const response = await api.patch(
        `school-evaluation/${schoolEvaluationId}/student-evaluation/${studentEvaluation.id}`,
        {
          ...studentEvaluation,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const evaluationScoresAdapter = createEntityAdapter({});

export const {
  selectAll: selectEvaluationScores,
  selectEntities: selectEvaluationScoresEntities,
  selectById: selectEvaluationScoresById,
} = evaluationScoresAdapter.getSelectors((state) => state.evaluationScores);

const evaluationScoresSlice = createSlice({
  name: 'evaluationScores',
  initialState: evaluationScoresAdapter.getInitialState({
    studentEvaluations: [],
    getAllScoresLoading: false,
  }),

  reducers: {
    clearEvaluationScores: (state) => {
      evaluationScoresAdapter.setAll(state, []);
    },
  },

  extraReducers: {
    [getEvaluationScores.fulfilled]: evaluationScoresAdapter.setAll,

    [addEvaluationScores.fulfilled]: evaluationScoresAdapter.addMany,

    [createEvaluationScore.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        evaluationScoresAdapter.addOne(state, {
          ...payload.data,
        });
      }
    },

    [editEvaluationScore.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        evaluationScoresAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },
  },
});

export const { clearEvaluationScores } = evaluationScoresSlice.actions;

export default evaluationScoresSlice.reducer;

export const getAllEvaluationScores = createAsyncThunk(
  'evaluationScores/getAllEvaluationScores',
  async ({ lessonPlanId, currentPage }, { dispatch, getState }) => {
    dispatch(clearEvaluationScores());
    dispatch(clearEvaluations());
    let propName = '';

    const { data } = await api.get('lesson-plan', {
      params: {
        parentLessonPlanId: lessonPlanId,
      },
    });

    if (data.data.length > 0) {
      propName = 'parentLessonPlanId';
    } else {
      propName = 'lessonPlanId';
    }

    await dispatch(
      getAllEvaluations({ [propName]: lessonPlanId, currentPage })
    );

    const {
      evaluations: { ids },
    } = getState();

    await Promise.all(
      ids.map(async (id) => {
        await dispatch(addEvaluationScores(id));
      })
    );
  }
);
