import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getOverviewDemonstrative = createAsyncThunk(
  'overviewDemonstrative/getOverviewDemonstrative',
  async () => {
    const response = await api.get('file');
    const { data } = await response.data;

    return data.map((report) => ({ ...report, id: report.updatedAt }));
  }
);

export const createOverviewDemonstrative = createAsyncThunk(
  'overviewDemonstrative/createOverviewDemonstrative',
  async (dates) => {
    api.get('report/overview-demonstrative', {
      params: {
        ...dates,
      },
    });

    const fileNameTemp = `fileType_${dates.start}_${dates.end}_~`;

    return { name: fileNameTemp, id: '01' };
  }
);

const overviewDemonstrativeAdapter = createEntityAdapter({});

export const { selectAll: selectOverviewDemonstratives } =
  overviewDemonstrativeAdapter.getSelectors(
    (state) => state.overviewDemonstrative
  );

const overviewDemonstrativeSlice = createSlice({
  name: 'overviewDemonstrative',
  initialState: overviewDemonstrativeAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getOverviewDemonstrative.fulfilled]: (state, { payload }) => {
      overviewDemonstrativeAdapter.setAll(state, payload);
    },

    [createOverviewDemonstrative.fulfilled]: (state, { payload }) => {
      overviewDemonstrativeAdapter.addOne(state, payload);

      const { id } = payload;
      state.ids = [id, ...state.ids.filter((existingId) => existingId !== id)];
    },
  },
});

export default overviewDemonstrativeSlice.reducer;
