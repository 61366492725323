import generateID from 'shared/utils/generateID';
import api from '../../api';

let lessonPlansTypesDB = [
  {
    id: '000000001',
    grade: {
      id: '706a5770-9d08-11ed-b500-d3b8a0ae9563',
      name: '3 ano',
      isActive: true,
      academicYear: {
        id: 'f03ee980-9cb2-11ed-a0c9-c7a64d69f795',
        year: '2023',
      },
      trainingTypes: [],
      educationLevel: {
        id: '7cde8350-9d08-11ed-b043-4ddda7bbcfaf',
        name: 'Ensino médio',
        isActive: true,
      },
    },
    curriculumComponent: {
      name: 'Matemática',
      id: 'aaf06561-f9dd-410f-8628-4dbfc4875514',
    },
    instructors: [
      {
        id: 'cf2399c5-8548-4fe8-a251-528e91c70bb1',
        type: 'INSTRUCTOR',
        name: 'Luffy',
      },
      {
        id: 'b5ee8d9d-f0d9-43ba-866c-402449c27259',
        type: 'INSTRUCTOR',
        name: 'Nami',
      },
      {
        id: '3632eb6f-ae8a-4fb9-be7e-c566ee85b686',
        type: 'INSTRUCTOR',
        name: 'Sanji',
      },
    ],
    isActive: true,
  },
];

api.onGet('lesson-plan').reply(() => {
  return [200, { data: lessonPlansTypesDB }];
});

api.onGet('lesson-plan/lessonPlanId').reply(() => {
  const lessonPlan = {
    id: '4cb7d97a-bb9a-4e8b-87b5-2721145c27fd',
    grade: {
      id: 'f8ab18f0-b9c2-11ed-bc4d-8de9ade83819',
      name: '3° ano',
      isActive: true,
      trainingTypes: [
        {
          id: '7f39717d-a349-4396-8d4a-6b187551b363',
          name: 'Itinerário Formativo Integrado',
          initials: 'IFI',
        },
      ],
      educationLevel: {
        id: '795c0800-b9bf-11ed-bb09-55c70b3aefe7',
        name: 'Ensino Médio',
        isActive: true,
      },
      academicYear: {
        id: '4c4e8a32-9562-4d63-8fd0-2ce255388671',
        year: '2023',
      },
    },
    curriculumComponent: {
      id: '6f9b9f90-b9c1-11ed-be25-b161037be532',
      name: 'Língua Portuguesa',
      isActive: true,
      knowledgeArea: {
        id: '09e3e3b0-b9c1-11ed-8f05-ebcbf5fd3794',
        name: 'Linguagens e suas Tecnologias',
        isActive: true,
      },
    },
    instructors: [],
    isActive: true,
  };

  return [200, lessonPlan];
});

api.onPost('lesson-plan').reply(() => {
  const newLessonPlansType = {
    id: generateID(),
    grade: {
      id: '706a5770-9d08-11ed-b500-d3b8a0ae9563',
      name: '3 ano',
      isActive: true,
      academicYear: {
        id: 'f03ee980-9cb2-11ed-a0c9-c7a64d69f795',
        year: '2023',
      },
      trainingTypes: [],
      educationLevel: {
        id: '7cde8350-9d08-11ed-b043-4ddda7bbcfaf',
        name: 'Ensino médio',
        isActive: true,
      },
    },
    curriculumComponent: { name: 'Historia' },
    instructors: [
      {
        id: '004',
        type: 'INSTRUCTOR',
        name: 'Zoro',
      },
    ],
    isActive: true,
  };

  lessonPlansTypesDB = [...lessonPlansTypesDB, newLessonPlansType];

  return [201, newLessonPlansType];
});

export const lessonPlansTypes = lessonPlansTypesDB;
