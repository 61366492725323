import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';

const users = {
  students: lazy(() => import('views/users/students/StudentsPage')),
  studentCreate: lazy(() => import('views/users/students/StudentCreatePage')),
  instructor: lazy(() => import('views/users/instructor/InstructorPage')),
  instructorCreate: lazy(() =>
    import('views/users/instructor/InstructorCreatePage')
  ),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const usersRoute = {
  path: `${appRoot}/usuarios`,
  label: 'menu.users',
  icon: 'user',
  exact: true,
  redirect: true,
  roles: [USER_ROLE.Admin],
  protected: true,
  to: `${appRoot}/usuarios/alunos`,
  subs: [
    {
      path: '/alunos',
      label: 'menu.student',
      component: users.students,
    },
    {
      path: '/aluno/adicionar',
      label: 'menu.users',
      component: users.studentCreate,
      hideInMenu: true,
    },
    {
      path: '/aluno/editar/:id?',
      label: 'menu.users',
      component: users.studentCreate,
      hideInMenu: true,
    },
    {
      path: '/aluno/:id?',
      label: 'menu.users',
      component: users.studentCreate,
      hideInMenu: true,
    },
    {
      path: '/professores',
      label: 'menu.instructor',
      component: users.instructor,
    },
    {
      path: '/professor/adicionar',
      label: 'menu.users',
      component: users.instructorCreate,
      hideInMenu: true,
    },
    {
      path: '/professor/editar/:id?',
      label: 'menu.users',
      component: users.instructorCreate,
      hideInMenu: true,
    },
    {
      path: '/professor/:id?',
      label: 'menu.users',
      component: users.instructorCreate,
      hideInMenu: true,
    },
  ],
};

export default usersRoute;
