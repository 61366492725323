import dashRoute from 'views/dashboards/routes';
import usersRoute from 'views/users/routes';
import parametersRoute from 'views/parameters/routes';
import eventsCalendarRoute from 'views/eventsCalendar/routes';
import registrationRoute from 'views/registration/routes';
import instructorProfileRoute from 'views/instructorView/classesPage/routes';
import lessonPlansRoute from 'views/lessonPlans/routes';
import studentEnrollmentRoute from 'views/studentEnrollment/routes';
import profileRoute from 'views/profile/routes';
import notificationsRoute from 'views/notification/routes';
import reportRoute from 'views/report/routes';
import documentsRoute from 'views/documents/routes';

const routesAndMenuItems = {
  mainMenuItems: [
    dashRoute,
    eventsCalendarRoute,
    usersRoute,
    parametersRoute,
    registrationRoute,
    lessonPlansRoute,
    instructorProfileRoute,
    studentEnrollmentRoute,
    profileRoute,
    notificationsRoute,
    reportRoute,
    documentsRoute,
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
