import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';

export const getCalendars = createAsyncThunk(
  'eventsCalendar/getCalendars',
  async (year) => {
    const response = await axios.get('/api/calendar', { params: { year } });
    const data = await response.data;
    return data;
  }
);

export const getYears = createAsyncThunk(
  'eventsCalendar/getYears',
  async () => {
    const response = await axios.get('/api/calendar/years');
    const data = await response.data;
    return data;
  }
);

export const createCalendar = createAsyncThunk(
  'eventsCalendar/createCalendar',
  async (calendar) => {
    const response = await axios.post('/api/calendar', calendar);
    const data = await response.data;

    return data;
  }
);

const calendarsAdapter = createEntityAdapter();

export const { selectAll: selectCalendars } = calendarsAdapter.getSelectors(
  (state) => state.eventsCalendar
);

const eventsCalendarSlice = createSlice({
  name: 'eventsCalendar',
  initialState: calendarsAdapter.getInitialState({
    isLoading: false,
    years: [],
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {
    [getCalendars.fulfilled]: calendarsAdapter.setAll,
    [getYears.fulfilled]: (state, action) => {
      state.years = action.payload;
    },
  },
});

export const { setLoading } = eventsCalendarSlice.actions;

export default eventsCalendarSlice.reducer;
