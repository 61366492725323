import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudentsData = createAsyncThunk(
  'studentPerformance/getStudentsData',
  async (studentEnrollmentIds) => {
    const response = await api.post('student-enrollment/students', {
      studentEnrollmentIds,
    });
    const { data } = await response.data;

    return data;
  }
);

export const createStudentPerformance = createAsyncThunk(
  'studentPerformance/createStudentPerformance',
  async (studentEnrollment) => {
    api.get(`report/student-enrollment/${studentEnrollment.id}`);

    const fileNameTemp = `boletim-escolar_${studentEnrollment.id}_~`;

    return { name: fileNameTemp, id: '01', studentEnrollment };
  }
);

export const getStudentPerformance = createAsyncThunk(
  'studentPerformance/getStudentPerformance',
  async (_, { dispatch }) => {
    const response = await api.get('file');
    const { data } = await response.data;

    const studentEnrollmentIds = [];

    data.forEach(({ name }) => {
      const [fileName, id] = name.split('_');
      if (
        fileName === 'boletim-escolar' &&
        !studentEnrollmentIds.includes(id)
      ) {
        studentEnrollmentIds.push(id);
      }
    });
    await dispatch(getStudentsData(studentEnrollmentIds));

    return data.map((report) => ({ ...report, id: report.updatedAt }));
  }
);

const studentPerformanceAdapter = createEntityAdapter({});

export const { selectAll: selectStudentPerformances } =
  studentPerformanceAdapter.getSelectors((state) => state.studentPerformance);

const studentPerformanceSlice = createSlice({
  name: 'studentPerformance',
  initialState: studentPerformanceAdapter.getInitialState({
    studentsData: [],
  }),
  reducers: {},
  extraReducers: {
    [getStudentPerformance.fulfilled]: (state, { payload }) => {
      studentPerformanceAdapter.setAll(state, payload);
    },

    [getStudentsData.fulfilled]: (state, { payload }) => {
      state.studentsData = payload;
    },

    [createStudentPerformance.fulfilled]: (state, { payload }) => {
      state.studentsData = [...state.studentsData, payload.studentEnrollment];

      studentPerformanceAdapter.addOne(state, payload);

      const { id } = payload;
      state.ids = [id, ...state.ids.filter((existingId) => existingId !== id)];
    },
  },
});

export default studentPerformanceSlice.reducer;
