const menuPtMessages = {
  'menu.accordion': 'Accordion',
  'menu.academic-year': 'Anos Letivos',
  'menu.account': 'Account',
  'menu.alerts': 'Alertas',
  'menu.analysis': 'Análise',
  'menu.analytic': 'Estatísticas',
  'menu.apps': 'Aplicativos',
  'menu.authentication': 'Autenticação',
  'menu.autocomplete': 'autocompletar',

  'menu.badge': 'Badge',
  'menu.basic-education': 'Educação Básica',
  'menu.billing': 'Cobrança',
  'menu.blocks': 'Blocos',
  'menu.blog': 'Blog',
  'menu.bookmarks': 'Bookmarks',
  'menu.bootstrap-icons': 'Bootstrap Icons',
  'menu.boxed-variations': 'Boxed Variations',
  'menu.breadcrumb': 'Breadcrumb',
  'menu.button-group': 'Grupo de botões',
  'menu.buttons': 'Botões',

  'menu.cadastre': 'Cadastro',
  'menu.calendar': 'Calendário',
  'menu.card': 'Cartão',
  'menu.carousel': 'Carousel',
  'menu.cart': 'Carrinho',
  'menu.categories': 'Categorias',
  'menu.charts': 'Gráficos',
  'menu.chat': 'Chat',
  'menu.checkbox-radio': 'Checkbox-Radio',
  'menu.checkout': 'Confira',
  'menu.clamp': 'Clamp',
  'menu.class': 'Turma',
  'menu.classes': 'Turmas',
  'menu.close-button': 'Botão Fechar',
  'menu.collapse': 'Colapso',
  'menu.coming-soon': 'Em breve',
  'menu.community': 'Comunidade',
  'menu.community-list': 'Lista da Comunidade',
  'menu.components': 'Componentes',
  'menu.connections': 'Connections',
  'menu.contacts': 'Contatos',
  'menu.context-menu': 'Menu contextual',
  'menu.controls': 'Controles',
  'menu.content': 'Content',
  'menu.courses': 'Cursos',
  'menu.cs-line-icons': 'CS Line Icons',
  'menu.cs-interface-icons': 'CS Interface Icons',
  'menu.cta': 'Cta',
  'menu.curriculum-component': 'Componentes Curriculares',
  'menu.curriculum-matrix': 'Matriz Curricular',
  'menu.customers': 'Clientes',

  'menu.dashboard': 'Painel',
  'menu.dashboards': 'Início',
  'menu.database': 'Base de dados',
  'menu.database-add': 'Novo banco de dados',
  'menu.database-detail': 'Detalhe do banco de dados',
  'menu.datatables': 'Tabelas de dados',
  'menu.date-picker': 'Seletor de data',
  'menu.default': 'Padrão',
  'menu.detail': 'Detalhes',
  'menu.details': 'Detalhes',
  'menu.devices': 'Devices',
  'menu.discount': 'Desconto',
  'menu.docs': 'Docs',
  'menu.docsdetail': 'Detalhe da documentação',
  'menu.documents': 'Documentos',
  'menu.downloads': 'Downloads',
  'menu.dropdowns': 'Listas suspensas',
  'menu.dropzone': 'Dropzone',

  'menu.editable-rows': 'Linhas editáveis',
  'menu.editable-boxed': 'Editable Boxed',
  'menu.editor': 'Editor',
  'menu.education-levels': 'Níveis de Ensino',
  'menu.elearning': 'Aprendizado',
  'menu.email': 'E-mail',
  'menu.empty': 'Vazio',
  'menu.error': 'Erro',
  'menu.eventsCalendar': 'Calendário de Eventos',
  'menu.explore': 'Explorar',

  'menu.faq': 'FAQ',
  'menu.filters': 'Filtros',
  'menu.forgot-password': 'Esqueci minha Senha',
  'menu.forms': 'Formulários',

  'menu.gallery': 'Galeria',
  'menu.general': 'Geral',
  'menu.generic-forms': 'Formulários genéricos',
  'menu.getting-started': 'Começando',
  'menu.grades': 'Séries',
  'menu.grid': 'Grade',

  'menu.help': 'Precisa de ajuda?',
  'menu.highSchool': 'Ensino Médio',
  'menu.home': 'Início',
  'menu.horizontal': 'Horizontal',
  'menu.hosting': 'Hospedagem',

  'menu.icons': 'Icons',
  'menu.images': 'Imagens',
  'menu.input-group': 'Grupo de entrada',
  'menu.input-mask': 'máscara de entrada',
  'menu.input-spinner': 'Input Spinner',
  'menu.instructor': 'Professores',
  'menu.interface': 'Interface',
  'menu.invoice': 'Fatura',

  'menu.knowledge-area': 'Áreas de Conhecimento',

  'menu.layouts': 'Layouts',
  'menu.lesson-plans': 'Planos de Aula',
  'menu.lightbox': 'Lightbox',
  'menu.list': 'Lista',
  'menu.list-group': 'Listar Grupo',
  'menu.login': 'Login',

  'menu.mailbox': 'Caixa de entrada',
  'menu.mailing': 'Enviando',
  'menu.maps': 'Maps',
  'menu.material': 'Material',
  'menu.menu': 'Menu',
  'menu.misc': 'Miscellaneous',
  'menu.miscellaneous': 'Diversos',
  'menu.mobile-only': 'Mobile Only',
  'menu.modal': 'Modal',
  'menu.my-data': 'Meus dados',
  'menu.my-schedules': 'Meus horários',

  'menu.navs': 'Navegadores',
  'menu.notification': 'Notificação',
  'menu.notifications': 'Notificações',

  'menu.offcanvas': 'Offcanvas',
  'menu.orders': 'Pedidos',

  'menu.pages': 'Páginas',
  'menu.pagination': 'Paginação',
  'menu.parameters': 'Parâmetros',
  'menu.password': 'Password',
  'menu.paths': 'Paths',
  'menu.player': 'Player',
  'menu.players': 'Players',
  'menu.plugins': 'Plug-ins',
  'menu.portfolio': 'Portfólio',
  'menu.pricing': 'Preço',
  'menu.products': 'Produtos',
  'menu.profile': 'Perfil',
  'menu.progress': 'Progresso',
  'menu.popovers': 'Popovers',

  'menu.quiz': 'Quiz',

  'menu.rating': 'Avaliação',
  'menu.register': 'Registro',
  'menu.registration': 'Matrícula',
  'menu.requests': 'Requests',
  'menu.reset-password': 'Resetar Senha',
  'menu.result': 'Result',

  'menu.school': 'Escola',
  'menu.scrollbar': 'Scrollbar',
  'menu.search': 'Pesquisar',
  'menu.security': 'Segurança',
  'menu.select': 'Selecione',
  'menu.series': 'Séries',
  'menu.server-side': 'Server Side',
  'menu.services': 'Serviços',
  'menu.settings': 'Configurações',
  'menu.shipping': 'Envio',
  'menu.shortcuts': 'Shortcuts',
  'menu.sidebar': 'Sidebar',
  'menu.slider': 'Slider',
  'menu.sms': 'SMS',
  'menu.sortable': 'Sortable',
  'menu.spinners': 'Spinners',
  'menu.standard': 'Padrão',
  'menu.stats': 'Estatísticas',
  'menu.steps': 'Degraus',
  'menu.storage': 'Armazenar',
  'menu.storefront': 'Vitrine',
  'menu.student': 'Alunos',
  'menu.student-enrollment': 'Matrícula',
  'menu.student-history': 'Histórico',
  'menu.support': 'Suporte',
  'menu.syllabus': 'Syllabus',

  'menu.tables': 'Tables',
  'menu.tabular-data': 'Dados tabulares',
  'menu.tags': 'Tags',
  'menu.tasks': 'Tarefas',
  'menu.thumbnails': 'Miniaturas',
  'menu.tickets': 'Tickets',
  'menu.tickets-detail': 'Detalhes do Teicket',
  'menu.time-picker': 'Time Picker',
  'menu.toasts': 'Toasts',
  'menu.tooltips': 'Tooltips',
  'menu.training-type': 'Tipos de Formações',
  'menu.typography': 'Typography',

  'menu.upgrade': 'Melhoria',
  'menu.users': 'Usuários',

  'menu.wizard': 'Wizard',

  'menu.validation': 'Validação',
  'menu.vertical': 'Vertical',
  'menu.vertical-hidden': 'Vertical Hidden',
  'menu.vertical-no-hidden': 'Vertical No Hidden',
  'menu.videos': 'Videos',
  'menu.visual': 'Visual',

  'menu.year-label': 'Escolha o ano que deseja gerenciar:',
};

export default menuPtMessages;
