import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudent = createAsyncThunk(
  'profile/getStudent',
  async (_, { getState }) => {
    const {
      auth: { currentUser },
    } = getState();

    try {
      const response = await api.get(`/user/${currentUser.id}`);

      return {
        success: true,
        data: response.data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editProfileStudent = createAsyncThunk(
  'profile/editProfileStudent',
  async (studentInfo) => {
    try {
      const response = await api.patch(`/user/student/${studentInfo.id}`, {
        ...studentInfo,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const profileAdapter = createEntityAdapter({});

const evaluationsSlice = createSlice({
  name: 'profile',
  initialState: profileAdapter.getInitialState({
    user: {},
  }),

  extraReducers: {
    [getStudent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.user = payload.data;
      }
    },

    [editProfileStudent.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.user = payload.data;
      }
    },
  },
});

export default evaluationsSlice.reducer;
