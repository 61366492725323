const instructorProfilePtMessages = {
  'instructorProfile.myClassSchedule': 'Meus horários de aula',
  'instructorProfile.timeline': 'Cronograma',
  'instructorProfile.general.objective.skills.developed':
    'Objetivo geral / Habilidades desenvolvidas',
  'instructorProfile.to.alter.this.class,.ask.the.secretary':
    'Para alterar esta aula, solicite à secretaria',
  'instructorProfile.registered': 'Registrado',
  'instructorProfile.opened': 'Em aberto',
  'instructorProfile.future': 'Futura',
  'instructorProfile.ChangeClass': 'Alterar aula',
  'instructorProfile.launchClass': 'Lançar aula',
  'instructorProfile.launchersClasses': 'Lançar aulas',

  'instructor-profile.registration-completed': 'Matrícula finalizada.',

  'instructor-profile.init-data-text':
    'Selecione o {year} para buscar as Turmas',
  'instructor-profile.schedule-day': 'Cronograma do dia',
};

export default instructorProfilePtMessages;
