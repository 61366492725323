import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';
import toDate from 'shared/dateFormatters/toDate';

const QUANTITY = {
  QUARTER: 4,
  TRIMESTER: 3,
  SEMESTER: 2,
};

export const getEducationLevels = createAsyncThunk(
  'educationLevels/getEducationLevels',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const { data } = await api.get('education-level', {
        params: {
          academicYearId: selectedYear.id,
        },
      });
      return data.data;
    } catch (error) {
      console.error('Educationa Level API error: ', error.message);
      return [];
    }
  }
);

export const createEducationLevel = createAsyncThunk(
  'educationLevels/createEducationLevel',
  async (educationLevel, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const response = await api.post('education-level', {
        ...educationLevel,
        academicYearId: selectedYear.id,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editEducationLevel = createAsyncThunk(
  'educationLevels/editEducationLevel',
  async (educationLevel) => {
    try {
      const response = await api.patch(`education-level/${educationLevel.id}`, {
        ...educationLevel,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const removeEducationLevel = createAsyncThunk(
  'educationLevels/removeEducationLevel',
  async (educationLevelId) => {
    try {
      const response = await api.delete(`education-level/${educationLevelId}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const createAcademicTerm = createAsyncThunk(
  'educationLevels/createAcademicTerm',
  async ({ academicTerms, educationLevelId }) => {
    try {
      const response = await api.post(
        `education-level/${educationLevelId}/academic-term`,
        {
          academicTerms,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data: { ...data, educationLevelId },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editAcademicTerm = createAsyncThunk(
  'educationLevels/editAcademicTerm',
  async ({ academicTerms, educationLevelId }) => {
    try {
      const response = await api.patch(
        `education-level/${educationLevelId}/academic-term`,
        {
          academicTerms,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data: { ...data, educationLevelId },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const educationLevelsAdapter = createEntityAdapter({});

export const {
  selectAll: selectEducationLevels,
  selectEntities: selectEducationLevelsEntities,
  selectById: selectEducationLevelsById,
} = educationLevelsAdapter.getSelectors((state) => state.educationLevels);

const educationLevelsSlice = createSlice({
  name: 'educationLevels',
  initialState: educationLevelsAdapter.getInitialState({
    isLoading: false,
    educationLevelModal: {
      show: false,
      type: 'new',
      data: null,
    },
    academicTermModal: {
      show: false,
      type: 'new',
      data: null,
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openEducationLevelModal: (state) => {
      state.educationLevelModal = { show: true, type: 'new', data: null };
    },
    closeEducationLevelModal: (state) => {
      state.educationLevelModal = { data: null, show: false, type: 'new' };
    },
    openEditEducationLevelModal: (state, { payload }) => {
      state.educationLevelModal = {
        data: payload,
        show: true,
        type: 'edit',
      };
    },

    openAcademicTermModal: (state, { payload }) => {
      const academicTerms = {
        terms: Array(QUANTITY[payload.type]).fill({
          start: null,
          end: null,
          showPerformance: null,
        }),
      };

      state.academicTermModal = {
        data: { ...payload, academicTerms },
        show: true,
        type: 'new',
      };
    },
    closeAcademicTermModal: (state) => {
      state.academicTermModal = {
        data: null,
        show: false,
        type: 'new',
      };
    },
    openEditAcademicTermModal: (state, { payload }) => {
      const academicTerms = {
        terms: payload.academicTerms.map(
          ({ start, end, showPerformance, id }) => {
            return {
              id,
              start: toDate(start),
              end: toDate(end),
              showPerformance: toDate(showPerformance),
            };
          }
        ),
      };

      state.academicTermModal = {
        data: { ...payload, academicTerms },
        show: true,
        type: 'edit',
      };
    },
  },
  extraReducers: {
    [getEducationLevels.fulfilled]: educationLevelsAdapter.setAll,

    [createEducationLevel.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        educationLevelsAdapter.addOne(state, {
          ...payload.data,
          academicTerms: [],
        });
      }
    },

    [editEducationLevel.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        educationLevelsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [removeEducationLevel.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        educationLevelsAdapter.removeOne(state, payload.data.id);
      }
      return state;
    },

    [createAcademicTerm.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        educationLevelsAdapter.updateOne(state, {
          id: payload.data.educationLevelId,
          changes: { academicTerms: payload.data.data },
        });
      }
    },

    [editAcademicTerm.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        educationLevelsAdapter.updateOne(state, {
          id: payload.data.educationLevelId,
          changes: { academicTerms: payload.data.data },
        });
      }
    },
  },
});

export const {
  setLoading,
  openEditEducationLevelModal,
  openEducationLevelModal,
  closeEducationLevelModal,
  openAcademicTermModal,
  closeAcademicTermModal,
  openEditAcademicTermModal,
} = educationLevelsSlice.actions;

export default educationLevelsSlice.reducer;
