const historyPtMessages = {
  'history.classroon': 'Aula',
  'history.addFrequency': 'Adicionar frequência',
  'history.addGrade': 'Adicionar Nota',
  'history.frequency': 'Frequência',
  'history.history': 'Histórico',
  'history.grade': 'Nota',
  'history.studentsGrade': 'Notas do aluno',
  'history.weight': 'Peso',
  'history.reasonForChange': 'Motivo da alteração',
};

export default historyPtMessages;
