const notificationsPtMessages = {
  'notification.addNotifications': 'Adicionar Notificação',
  'notification.subject': 'Assunto',
  'notification.notifications': 'Notificações',
  'notification.toProgramed': 'Programado para',
  'notification.public': 'Público',
  'notification.targetAudience': 'Público-Alvo',

  'notification.new-message': 'Nova mensagem',

  'notification.select-recipients': 'Selecionar destinatários',
  'notification.send-all-instructors': 'Enviar para todos os professores',
};

export default notificationsPtMessages;
