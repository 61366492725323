import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getRegistrations = createAsyncThunk(
  'registrations/getRegistrations',
  async (gradeId) => {
    const response = await api.get('student-enrollment', {
      params: { gradeId },
    });
    const { data } = await response.data;

    return data;
  }
);

export const createRegistration = createAsyncThunk(
  'registration/createRegistration',
  async (registration) => {
    try {
      const response = await api.post('student-enrollment', {
        ...registration,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      switch (err.response.status) {
        case 422:
          return {
            success: false,
            response: err.response.data,
          };
        default:
          throw err;
      }
    }
  }
);

export const editRegistration = createAsyncThunk(
  'registration/editRegistration',
  async (registration) => {
    try {
      const response = await api.patch(
        `student-enrollment/${registration.id}`,
        {
          ...registration,
        }
      );
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const removeRegistration = createAsyncThunk(
  'registration/removeRegistration',
  async (registrationId) => {
    try {
      const response = await api.delete(`student-enrollment/${registrationId}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const gradesToSelect = createAsyncThunk(
  'registration/gradesToSelect',
  async ({ currentPage }, { dispatch, getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get('grade', {
      params: {
        currentPage: currentPage ?? null,
        academicYearId: selectedYear.id,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(gradesToSelect({ currentPage: data.currentPage + 1 }));
    }

    return data;
  }
);

const registrationsAdapter = createEntityAdapter({});

export const {
  selectAll: selectRegistrations,
  selectEntities: selectRegistrationsEntities,
  selectById: selectRegistrationById,
} = registrationsAdapter.getSelectors((state) => state.registration);

const registrationsSlice = createSlice({
  name: 'registration',
  initialState: registrationsAdapter.getInitialState({
    isLoading: false,
    showArchivingModal: false,
    registrationSelects: {
      year: '',
      grade: '',
    },
    editRegistrationModal: {
      show: false,
      data: null,
    },
    reportModal: {
      show: false,
      data: null,
    },
    gradesToSelect: [],
  }),
  reducers: {
    openArchivingModal: (state) => {
      state.showArchivingModal = true;
    },
    closeArchivingModal: (state) => {
      state.showArchivingModal = false;
    },
    setYear: (state, { payload }) => {
      state.registrationSelects.year = payload;
    },
    setGrade: (state, { payload }) => {
      state.registrationSelects.grade = payload;
    },
    openEditRegistrationModal: (state, { payload }) => {
      state.editRegistrationModal = { show: true, data: payload };
    },
    closeEditRegistrationModal: (state) => {
      state.editRegistrationModal.show = false;
    },
    clearRegistrations: (state) => {
      registrationsAdapter.setAll(state, []);
    },
  },
  extraReducers: {
    [getRegistrations.pending]: (state) => {
      state.isLoading = true;
    },
    [getRegistrations.fulfilled]: (state, { payload }) => {
      registrationsAdapter.setAll(state, payload);
      state.isLoading = false;
    },
    [getRegistrations.rejected]: (state) => {
      state.isLoading = false;
    },
    [createRegistration.pending]: (state) => {
      state.isLoading = true;
    },

    [createRegistration.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        return registrationsAdapter.addOne(state, payload.data);
      }
      return state;
    },
    [createRegistration.rejected]: (state) => {
      state.isLoading = false;
    },

    [editRegistration.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        registrationsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [removeRegistration.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        registrationsAdapter.removeOne(state, payload.data.id);
      }
      return state;
    },

    [gradesToSelect.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        state.gradesToSelect = payload.data;
      } else {
        state.gradesToSelect = [...state.gradesToSelect, ...payload.data];
      }
    },
  },
});

export const {
  openArchivingModal,
  closeArchivingModal,
  setYear,
  setGrade,
  openEditRegistrationModal,
  closeEditRegistrationModal,
  closeReportModal,
  clearRegistrations,
} = registrationsSlice.actions;

export default registrationsSlice.reducer;
