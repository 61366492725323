import api from '../../api';

const eventsCalendarDB = [
  {
    id: '001',
    title: 'Feriado Municipal',
    description: 'Descrição',
    type: 'HOLIDAY',
    start: '2023-10-01',
    end: '2023-10-05',
    startHour: null,
    endHour: null,
  },
  {
    id: '002',
    title: 'Reunião',
    description: 'Descrição',
    type: 'MEETING',
    start: '2023-10-07',
    end: null,
    startHour: '12:00',
    endHour: '13:00',
  },
];

api.onGet('events').reply(() => {
  return [200, eventsCalendarDB];
});
