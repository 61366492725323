const loginPtMessages = {
  'login.agree-text':
    'Estou ciente que entreguei o questionário Par-q e o atestado de saúde. ',

  'login.back-to-login': 'Voltar para a tela de login.',
  'login.btn-forgot-password': 'Esqueceu sua senha?',
  'login.btn-enter': 'Entrar',
  'login.change-password': 'Alterar senha',

  'login.description':
    'Gerencie alunos, professores, avaliações, históricos e muito mais...',

  'login.error-user-not-found':
    'Usuário não encontrado ou credenciais inválidas!',

  'login.forgot-password': 'Esqueceu sua senha?',
  'login.forgot-password-description':
    'Informe seu usuário para receber um e-mail de redefinição de senha.',
  'login.forgot-password-toast-success': 'E-mail enviado.',
  'login.reset-password-toast-success': 'Sua senha foi alterada com sucesso.',

  'login.has-register-text': 'Já tem cadastro? Clique aqui para fazer login',

  'login.input-email-validation': 'Informe um E-mail válido',
  'login.input-password-placeholder': 'Senha',
  'login.repeat-password': 'Repetir senha',
  'login.input-password-min-validation': 'A senha deve ter no mínimo 6 dígitos',
  'login.invalid-user-id': 'CPF ou CNPJ inválido',

  'login.passwords-must-match': 'As senhas devem corresponder',

  'login.reset-password': 'Redefinir Senha',

  'login.subtitle': 'Gerenciamento escolar em um único lugar!',

  'login.title': 'Didati',
  'login.type-new-password': 'Digite abaixo sua nova senha.',

  'login.user': 'Usuário',

  'login.welcome': 'Bem-vindo(a), \nvamos iniciar!',
  'login.welcome-register': 'Bem-vindo(a), \nvamos iniciar o seu cadastro!',

  'login.welcome-message': 'Por favor entre com suas credenciais.',

  'login.use-term-link': 'termos de uso',
  'login.privacy-policies-link': 'políticas de privacidade',

  'login.use-term-privacy':
    'Li e estou de acordo com os {useTermLink} e {privacyPoliciesLink} da SMELJ.',
};

export default loginPtMessages;
