import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const instructorProfile = {
  ClassesPage: lazy(() =>
    import('views/instructorView/classesPage/ClassesPage')
  ),
  class: lazy(() => import('views/instructorView/classPage/ClassPage')),
  frequency: lazy(() =>
    import('views/instructorView/frequencyPage/FrequencyPage')
  ),
  evaluations: lazy(() => import('views/evaluations/EvaluationsPage')),
  evaluationScores: lazy(() =>
    import('views/instructorView/evaluationScores/EvaluationScoresPage')
  ),
  scores: lazy(() => import('views/scores/ScoresPage')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const instructorProfileRoute = {
  path: `${appRoot}/turmas`,
  exact: true,
  icon: 'online-class',
  label: 'menu.classes',
  component: instructorProfile.ClassesPage,
  protected: true,
  roles: [USER_ROLE.Instructor],
  to: `${appRoot}/turmas`,
  subs: [
    {
      path: '/aulas',
      label: 'commons.classes',
      exact: true,
      component: instructorProfile.class,
      hideInMenu: true,
    },
    {
      path: '/aulas/frequencia',
      label: 'commons.frequency',
      component: instructorProfile.frequency,
      hideInMenu: true,
    },
    {
      path: '/avaliacoes',
      label: 'commons.evaluations',
      exact: true,
      component: instructorProfile.evaluations,
      hideInMenu: true,
    },
    {
      path: '/avaliacoes/lancar-notas',
      component: instructorProfile.evaluationScores,
      hideInMenu: true,
    },
    {
      path: '/notas',
      component: instructorProfile.scores,
      hideInMenu: true,
      exact: true,
    },
  ],
};

export default instructorProfileRoute;
