import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const documents = {
  documentsPage: lazy(() => import('views/documents/DocumentsPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const documentsRoute = {
  path: `${appRoot}/documentos`,
  exact: true,
  icon: 'folder',
  label: 'commons.documents',
  component: documents.documentsPage,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/documentos`,
  schoolOnly: true,
};

export default documentsRoute;
