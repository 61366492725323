import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const parameters = {
  training: lazy(() => import('views/parameters/training/TrainingTypePage')),
  knowledge: lazy(() => import('views/parameters/knowledge/KnowledgeAreaPage')),
  curriculum: lazy(() =>
    import('views/parameters/curriculum/CurriculumComponentPage')
  ),
  education: lazy(() =>
    import('views/parameters/education/EducationLevelsPage')
  ),
  grades: lazy(() => import('views/parameters/grades/GradesPage')),

  academicYear: lazy(() =>
    import('views/parameters/academicYear/AcademicYearPage')
  ),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const parametersRoute = {
  path: `${appRoot}/parametros`,
  label: 'menu.parameters',
  icon: 'settings-1',
  exact: true,
  redirect: true,
  roles: [USER_ROLE.Admin],
  protected: true,
  to: `${appRoot}/parametros`,
  subs: [
    {
      path: '/anos-letivos',
      label: 'menu.academic-year',
      component: parameters.academicYear,
    },
    {
      path: '/niveis-de-ensino',
      label: 'menu.education-levels',
      component: parameters.education,
    },
    {
      path: '/areas-de-conhecimento',
      label: 'menu.knowledge-area',
      component: parameters.knowledge,
    },
    {
      path: '/series',
      label: 'menu.series',
      component: parameters.grades,
    },
    {
      path: '/componentes-curriculares',
      label: 'menu.curriculum-component',
      component: parameters.curriculum,
    },
    {
      path: '/tipos-de-formacoes',
      label: 'menu.training-type',
      component: parameters.training,
    },
  ],
};

export default parametersRoute;
