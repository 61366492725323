import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import api from 'services/api';
import difference from 'shared/utils/difference';

export const getStudents = createAsyncThunk(
  'students/getStudents',
  async ({ currentPage, searchFor }, { getState }) => {
    const {
      students: { pagination },
    } = getState();
    const response = await api.get('/user', {
      params: {
        type: 'STUDENT',
        currentPage: currentPage ?? pagination.currentPage,
        searchFor,
      },
    });

    return response.data;
  }
);

export const createStudent = createAsyncThunk(
  'students/createStudent',
  async (newStudent) => {
    try {
      const response = await api.post('/user/student', {
        ...newStudent,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const editStudent = createAsyncThunk(
  'students/editStudent',
  async (student, { getState }) => {
    const { students } = getState();

    const studentDiff = difference(student, students.selectedStudent.data);

    try {
      const response = await api.patch(`/user/student/${student.id}`, {
        ...studentDiff,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const getRegistrationsByStudent = createAsyncThunk(
  'students/getRegistrationsByStudent',
  async (studentId, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get(
      `/student-enrollment/academic-year/${selectedYear.id}/student/${studentId}`
    );

    return response.data;
  }
);

const studentsAdapter = createEntityAdapter();

export const { selectAll: selectStudents } = studentsAdapter.getSelectors(
  (state) => state.students
);

export const ROWS_PER_PAGE_DEFAULT = 20;

const studentSlice = createSlice({
  name: 'students',
  initialState: studentsAdapter.getInitialState({
    isLoading: false,
    responsibles: [],
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      totalPages: 1,
      totalRowCount: 0,
    },
    selectedResponsible: {
      data: {},
      type: null,
    },
    selectedStudent: {
      data: { address: null },
      type: 'new',
    },
  }),
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addResponsible: (state, action) => {
      state.responsibles = [...state.responsibles, action.payload];
    },
    editResponsible: (state, { payload }) => {
      const index = state.responsibles.findIndex(({ id }) => id === payload.id);
      state.responsibles[index] = payload;
    },
    removeResponsible: (state, { payload }) => {
      state.responsibles = state.responsibles.filter(
        ({ id }) => id !== payload
      );
    },
    editSelectResponsible: (state, { payload }) => {
      state.selectedResponsible = {
        data: payload,
        type: 'edit',
      };
    },
    resetSelectedResponsible: (state) => {
      state.selectedResponsible = {
        data: {},
        type: null,
      };
    },
    showSelectStudent: (state, { payload }) => {
      state.selectedStudent = {
        data: payload,
        type: 'show',
      };
    },
    editSelectStudent: (state, { payload }) => {
      state.selectedStudent = {
        data: payload,
        type: 'edit',
      };
      state.responsibles = payload.responsibles;
    },
    clearStudents: (state) => {
      studentsAdapter.setAll(state, []);
    },
  },
  extraReducers: {
    [getStudents.pending]: (state) => {
      state.isLoading = true;
    },
    [getStudents.fulfilled]: (state, { payload }) => {
      studentsAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.isLoading = false;
    },
    [getStudents.rejected]: (state) => {
      state.isLoading = false;
    },

    [createStudent.pending]: (state) => {
      state.isLoading = true;
    },
    [createStudent.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { payload } = action;
      if (payload.success) {
        studentsAdapter.addOne(state, payload.data);
      }
      return state;
    },
    [createStudent.rejected]: (state) => {
      state.isLoading = false;
    },
    [editStudent.pending]: (state) => {
      state.isLoading = true;
    },
    [editStudent.fulfilled]: (state, { payload }) => {
      studentsAdapter.updateOne(state, payload);
      state.isLoading = false;
    },
    [editStudent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  setLoading,
  addResponsible,
  removeResponsible,
  editResponsible,
  editSelectResponsible,
  resetSelectedResponsible,
  showSelectStudent,
  editSelectStudent,
  clearStudents,
} = studentSlice.actions;

export default studentSlice.reducer;
